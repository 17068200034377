import styled from 'styled-components'
import { COLOR, GAP, TYPOGRAPHY } from './theme'

const Button = styled.button`
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ big }) => (big ? GAP.sm : GAP.xs)} ${GAP.sm};
  background-color: ${({ primary, green, transparent }) =>
    primary
      ? green
        ? COLOR.green
        : COLOR.black
      : transparent
        ? 'transparent'
        : COLOR.white};
  color: ${({ primary }) => (primary ? COLOR.white : COLOR.black)};
  border: 1px solid ${({ green }) => (green ? COLOR.green : COLOR.black)};
  border-radius: 8px;
  font-size: ${TYPOGRAPHY.size.sm};
  transition: 0.3s all;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: ${COLOR.blue};
    background-color: ${COLOR.blue};
    color: ${COLOR.white};
  }

  &:disabled {
    border-color: ${COLOR.lightGray};
    background-color: ${COLOR.lightGray};
    color: ${COLOR.darkGray};
    cursor: default;
  }
`

export default Button
