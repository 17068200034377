import styled from 'styled-components'
import { GAP } from './theme'

const Box = styled.div`
  margin: 0;
  padding: ${GAP.md};
  width: 100%;
  ${({ bg }) => (bg ? `background-color: ${bg};` : '')}
  border-radius: 4px;
`

export default Box
