import { Controller, useForm } from 'react-hook-form'
import { Box, Button, Flex, GAP, Grid, Heading, Input, Select } from 'ui'

// const Q_TYPE_OPTS = [
//   { value: 'section', label: 'Section specific' },
//   { value: 'course', label: 'Course wide' },
// ]

const QuestionModal = ({ question, submitModal, closeModal }) => {
  const isAdd = !question
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isSubmitting },
  } = useForm()
  const formValues = watch()
  const optionLabels = {
    option1: 'Option 1',
    option2: 'Option 2',
    option3: 'Option 3',
    option4: 'Option 4',
  }
  const correctOptions = [
    { value: 'option1', label: optionLabels.option1 },
    { value: 'option2', label: optionLabels.option2 },
    ...(formValues?.option3 || (!isDirty && question?.option3)
      ? [{ value: 'option3', label: optionLabels.option3 }]
      : []),
    ...(formValues?.option4 || (!isDirty && question?.option4)
      ? [{ value: 'option4', label: optionLabels.option4 }]
      : []),
  ]

  return (
    <Box>
      <Heading secondary>
        {isAdd ? 'Add a new question' : 'Edit question'}
      </Heading>
      <form onSubmit={handleSubmit(submitModal)}>
        <Controller
          control={control}
          name="id"
          render={({ field }) => <Input type="hidden" {...field} />}
          defaultValue={question?.id || ''}
        />
        <Controller
          control={control}
          name="question"
          render={({ field }) => (
            <Input
              label="Question*"
              error={errors.question}
              autoFocus
              {...field}
            />
          )}
          rules={{ required: 'Question is required' }}
          defaultValue={question?.question || ''}
        />
        {/* <Grid cols="45% 45%" gap={GAP.xs}>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <Select
                label="Type*"
                options={Q_TYPE_OPTS}
                error={errors.type}
                {...field}
              />
            )}
            rules={{ required: 'Type is required' }}
            defaultValue={
              !question || question.sectionId ? Q_TYPE_OPTS[0] : Q_TYPE_OPTS[1]
            }
          />
        </Grid> */}
        <Controller
          control={control}
          name="option1"
          render={({ field }) => (
            <Input label="Option 1*" error={errors.option1} {...field} />
          )}
          rules={{ required: 'This option is required' }}
          defaultValue={question?.option1 || ''}
        />
        <Controller
          control={control}
          name="option2"
          render={({ field }) => (
            <Input label="Option 2*" error={errors.option2} {...field} />
          )}
          rules={{ required: 'This option is required' }}
          defaultValue={question?.option2 || ''}
        />
        <Controller
          control={control}
          name="option3"
          render={({ field }) => (
            <Input label="Option 3" error={errors.option3} {...field} />
          )}
          defaultValue={question?.option3 || ''}
        />
        <Controller
          control={control}
          name="option4"
          render={({ field }) => (
            <Input label="Option 4" error={errors.option4} {...field} />
          )}
          defaultValue={question?.option4 || ''}
        />
        <Grid cols="45% 45%" gap="10%">
          <Controller
            control={control}
            name="correct"
            render={({ field }) => (
              <Select
                label="Correct answer*"
                options={correctOptions}
                error={errors.correct}
                {...field}
              />
            )}
            rules={{ required: 'Correct answer is required' }}
            defaultValue={
              question
                ? {
                    value: question.correct,
                    label: optionLabels[question.correct],
                  }
                : ''
            }
          />
          <Controller
            control={control}
            name="weight"
            render={({ field }) => (
              <Input
                type="number"
                label="Weight*"
                error={errors.weight}
                {...field}
              />
            )}
            rules={{ required: 'Weight is required' }}
            defaultValue={question?.weight || '1'}
          />
        </Grid>
        <Controller
          control={control}
          name="explanation"
          render={({ field }) => (
            <Input
              isMultiLine={true}
              label="Explanation"
              error={errors.explanation}
              {...field}
            />
          )}
          defaultValue={question?.explanation || ''}
        />
        <Flex justifyContent="flex-end" margin={`${GAP.lg} 0 0 0`}>
          <Button onClick={closeModal} margin={`0 ${GAP.sm} 0 0 `}>
            Cancel
          </Button>
          <Button
            type="submit"
            primary
            disabled={(!isAdd && !isDirty) || isSubmitting}
          >
            {isAdd ? 'Add' : 'Update'}
          </Button>
        </Flex>
      </form>
    </Box>
  )
}

export default QuestionModal
