import { createGlobalStyle } from 'styled-components'
import { COLOR, TYPOGRAPHY } from 'ui'

const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    background-color: ${COLOR.black};
    font-family: ${TYPOGRAPHY.font};
    font-size: ${TYPOGRAPHY.size.sm};
    width: 100%;
    min-height: 100vh;
  }

  #app {
    width: 100%;
    min-height: 100vh;
  }

  * {
    box-sizing: border-box;
  }

  img {
    pointer-events: none;
    /* user-select: none;
    -webkit-user-drag: none; */
  }
`

export default GlobalStyles
