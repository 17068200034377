import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Button, Flex, GAP, Heading, Input } from 'ui'

const CourseModal = ({ course, submitModal, closeModal }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm()
  const isAdd = !course

  return (
    <Content>
      <Heading secondary>
        {isAdd ? 'Create a new course' : 'Course settings'}
      </Heading>
      <form onSubmit={handleSubmit(submitModal)}>
        {!isAdd && (
          <Controller
            control={control}
            name="id"
            render={({ field }) => (
              <Input label="Course ID" readOnly={true} {...field} />
            )}
            rules={{ required: 'Course ID is required' }}
            defaultValue={course.id}
          />
        )}
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <Input label="Title*" error={errors.title} autoFocus {...field} />
          )}
          rules={{ required: 'Course title is required' }}
          defaultValue={course?.title || ''}
        />
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Input isMultiLine={true} label="Description" {...field} />
          )}
          defaultValue={course?.description || ''}
        />
        <Flex justifyContent="flex-end" margin={`${GAP.lg} 0 0 0`}>
          <Button onClick={closeModal} margin={`0 ${GAP.sm} 0 0 `}>
            Cancel
          </Button>
          <Button
            type="submit"
            primary
            disabled={(!isAdd && !isDirty) || isSubmitting}
          >
            {isAdd ? 'Create' : 'Update'}
          </Button>
        </Flex>
      </form>
    </Content>
  )
}

const Content = styled.div`
  position: relative;
  margin: 0;
  padding: ${GAP.md};
`

export default CourseModal
