import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Layout from 'components/Layout'
import {
  Button,
  Flex,
  GAP,
  Input,
  Loader,
  MEDIA_QUERY,
  Select,
  Subheading,
} from 'ui'
import { apiCall, reportError } from 'utils'
import { useAppContext } from 'context'
import { SUPPORT_EMAIL, TIME_ZONES } from 'config'

const Account = () => {
  const navigate = useNavigate()
  const { user, getUserData, isLoading, setIsLoading, setError } =
    useAppContext()
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm()
  const timezoneOptions = useMemo(
    () => TIME_ZONES.map((e) => ({ value: e, label: e })),
    [],
  )
  const languageOptions = useMemo(
    () => [
      { value: 'en', label: 'English' },
      // { value: 'fr', label: 'French' },
    ],
    [],
  )
  const currentTimezone = timezoneOptions.find(
    (e) => e.value === user?.timezone,
  )
  const currentLanguage = languageOptions.find(
    (e) => e.value === user?.language,
  )
  const currentPlan = user?.plan || {}

  const goToSubscription = () => {
    navigate('/subscription')
  }

  const onSubmit = async (formData) => {
    setIsLoading(true)

    const success = await apiCall({
      query: 'putProfile',
      data: {
        ...formData,
        timezone: formData.timezone.value,
        language: formData.language.value,
      },
    }).catch((error) => {
      reportError({ source: 'profile', error })
      setError('Failed to save details')
      return false
    })

    if (success) {
      await getUserData()
    }

    setIsLoading(false)
  }

  // const deleteAccount = () => {
  //   const confirmedDelete = confirm(
  //     "Are you sure you want to delete your account and data? It can't be undone.",
  //   );
  //   if (!confirmedDelete) return;

  //   const confirmedEmail = confirm(
  //     'This will launch your default email client. Continue?',
  //   );
  //   if (!confirmedEmail) return;

  //   window.location = `mailto:${SUPPORT_EMAIL}?subject=Account Deletion Request`;
  // };

  // const requestData = () => {
  //   const confirmedEmail = confirm(
  //     'This will launch your default email client. Continue?',
  //   );
  //   if (!confirmedEmail) return;

  //   window.location = `mailto:${SUPPORT_EMAIL}?subject=Account Data Request`;
  // };

  const contactUs = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(
      'This will launch your default email client. Continue?',
    )
    if (!confirmed) return

    window.location = `mailto:${SUPPORT_EMAIL}`
  }

  const menu = [
    // <Button
    //   key='deleteAccountButton'
    //   onClick={deleteAccount}
    //   aria-label='Delete your account and data'
    // >
    //   Delete Account
    // </Button>,
    // <Button
    //   key='requestDataButton'
    //   onClick={requestData}
    //   aria-label='Request data'
    // >
    //   Request Data
    // </Button>,
    <Button key="contactUsButton" onClick={contactUs} aria-label="Contact us">
      Contact Us
    </Button>,
  ]

  return (
    <Layout page={{ title: 'Account', menu }}>
      {isLoading || !user ? (
        <Loader big />
      ) : (
        <Wrapper>
          <Container>
            <Subheading>Details</Subheading>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormCols>
                <div>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <Input label="Email*" readOnly={true} {...field} />
                    )}
                    defaultValue={user.email}
                  />
                  <Controller
                    control={control}
                    name="fullname"
                    render={({ field }) => (
                      <Input
                        label="Full name*"
                        error={errors.fullname}
                        {...field}
                      />
                    )}
                    rules={{ required: 'Name is required' }}
                    defaultValue={user.name.full}
                  />
                  <Controller
                    control={control}
                    name="timezone"
                    render={({ field }) => (
                      <Select
                        label="Timezone*"
                        options={timezoneOptions}
                        isSearchable={true}
                        error={errors.timezone}
                        {...field}
                      />
                    )}
                    rules={{ required: 'Timezone is required' }}
                    defaultValue={currentTimezone}
                  />
                  <Controller
                    control={control}
                    name="language"
                    render={({ field }) => (
                      <Select
                        label="Language*"
                        options={languageOptions}
                        error={errors.language}
                        {...field}
                      />
                    )}
                    rules={{ required: 'Language is required' }}
                    defaultValue={currentLanguage}
                  />
                  {/* <Controller
                  control={control}
                  name='city'
                  render={({ field }) => (
                    <Input label='City' error={errors.title} {...field} />
                  )}
                  defaultValue={user.city}
                />
                <Controller
                  control={control}
                  name='country'
                  render={({ field }) => <Input label='Country' {...field} />}
                  defaultValue={user.country}
                />
                <Controller
                  control={control}
                  name='language'
                  render={({ field }) => <Input label='Language' {...field} />}
                  defaultValue={user.language}
                /> */}
                </div>
              </FormCols>
              <Flex justifyContent="flex-end">
                <Button type="submit" primary shadow disabled={!isDirty}>
                  Update
                </Button>
              </Flex>
            </form>
          </Container>
          <Container>
            <Subheading>Subscription</Subheading>
            <div>
              Status:{' '}
              <strong>
                {user.isEnabled && user.subscription?.isActive
                  ? 'Active'
                  : 'Canceled'}
              </strong>
            </div>
            {user.isEnabled && currentPlan && (
              <>
                <div>Plan: {currentPlan.name}</div>
                {/* <div>Next bill: {currentPlan.date}</div> */}
                <div>
                  Rate: ${user.subscription.isActive ? currentPlan.rate : 0} per
                  month
                </div>
                <br />
                <div>
                  Features:
                  <List>
                    <li>
                      Enroll quota: {currentPlan.enrollCount} courses to enroll
                      in
                    </li>
                    <li>
                      Course quota: {currentPlan.courseCount} courses to create
                    </li>
                    <li>
                      Max course capacity: {currentPlan.courseCapacity} learners
                      per course
                    </li>
                    {currentPlan.id !== 'scale' ? (
                      <li>Support</li>
                    ) : (
                      <li>Priority support</li>
                    )}
                  </List>
                </div>
              </>
            )}
            {user.isEnabled && (
              <Flex justifyContent="flex-end">
                <Button primary shadow onClick={goToSubscription}>
                  Manage
                </Button>
              </Flex>
            )}
          </Container>
        </Wrapper>
      )}
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: ${GAP.xxl};
  margin: ${GAP.lg} auto;
  padding: 0 ${GAP.sm};
  max-width: 1200px;

  ${MEDIA_QUERY.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: ${GAP.sm};
  width: 100%;
`

const FormCols = styled.div`
  display: grid;
  grid-gap: ${GAP.md};
  grid-template-columns: 1fr;
  margin: 0;
  padding: 0;

  /* ${MEDIA_QUERY.sm} {
    grid-template-columns: 1fr 1fr;
  } */
`

const List = styled.ul`
  li {
    margin-bottom: ${GAP.xs};
  }
`

export default Account
