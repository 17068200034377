import { COLOR } from './theme'

export const Menu = (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <g>
      <path
        d="M4 6H20M4 12H20M4 18H20"
        stroke={COLOR.green}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
)

export const Home = (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.4498 10.275L11.9998 3.1875L2.5498 10.275L2.9998 11.625H3.7498V20.25H20.2498V11.625H20.9998L21.4498 10.275ZM5.2498 18.75V10.125L11.9998 5.0625L18.7498 10.125V18.75H14.9999V14.3333L14.2499 13.5833H9.74988L8.99988 14.3333V18.75H5.2498ZM10.4999 18.75H13.4999V15.0833H10.4999V18.75Z"
        fill={COLOR.white}
      />
    </g>
  </svg>
)

export const Checkmark = (
  <svg width={18} height={12} viewBox="0 0 18 12" fill="none">
    <path d="M17.736.36a.981.981 0 00-1.272 0L5.68 9.943 1.536 6.26a.981.981 0 00-1.272 0 .739.739 0 000 1.131l4.78 4.25a.982.982 0 001.273 0l11.42-10.15a.739.739 0 000-1.132z" />
  </svg>
)
