import { Controller, useForm } from 'react-hook-form'
import { Box, Button, Checkbox, Flex, GAP, Heading, Input } from 'ui'

const SectionModal = ({ section, deleteSection, submitModal, closeModal }) => {
  const isAdd = !section
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm()

  return (
    <Box>
      <Heading secondary>
        {isAdd ? 'Add a new section' : 'Section settings'}
      </Heading>
      <form onSubmit={handleSubmit(submitModal)}>
        <Controller
          control={control}
          name="id"
          render={({ field }) => <Input type="hidden" {...field} />}
          defaultValue={section?.id || ''}
        />
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <Input label="Title*" error={errors.title} autoFocus {...field} />
          )}
          rules={{ required: 'Title is required' }}
          defaultValue={section?.title || ''}
        />
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Input
              isMultiLine={true}
              label="Description"
              error={errors.description}
              {...field}
            />
          )}
          defaultValue={section?.description || ''}
        />
        {!isAdd && (
          <Controller
            control={control}
            name="hasSupplement"
            render={({ field }) => (
              <Checkbox
                label="Enable supplementary material"
                checked={section.hasSupplement}
                margin={`${GAP.lg} 0 0 0`}
                {...field}
              />
            )}
          />
        )}
        <Flex justifyContent="space-between" margin={`${GAP.lg} 0 0 0`}>
          <div>
            {!isAdd && deleteSection && (
              <Button onClick={deleteSection(section.id)}>Delete</Button>
            )}
          </div>
          <div>
            <Button onClick={closeModal} margin={`0 ${GAP.sm} 0 0 `}>
              Cancel
            </Button>
            <Button
              type="submit"
              primary
              disabled={(!isAdd && !isDirty) || isSubmitting}
            >
              {isAdd ? 'Add' : 'Update'}
            </Button>
          </div>
        </Flex>
      </form>
    </Box>
  )
}

export default SectionModal
