import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Layout from 'components/Layout'
import {
  BORDER_RADIUS,
  Button,
  COLOR,
  GAP,
  Loader,
  MEDIA_QUERY,
  Paragraph,
  ScrollProgress,
  Subheading,
  TYPOGRAPHY,
} from 'ui'
import { apiCall } from 'utils'
import { useAppContext } from 'context'

const QUIZ_NOTE_THRESHOLD = 50

const Quiz = () => {
  const navigate = useNavigate()
  const {
    user,
    setError,
    isLoading,
    setIsLoading,
    getCourseData,
    apiCache,
    setApiCache,
  } = useAppContext()
  const { courseId, sectionNumber = '1' } = useParams()
  const [answers, setAnswers] = useState({})
  const [score, setScore] = useState()
  const timeOnPageStart = useRef(new Date())
  const timeOnPageElapsed = useRef(0)
  const course = apiCache.course[courseId]
  const sections = course?.sections || []
  const section = sections.find((c) => c.number === Number(sectionNumber))
  const sectionId = section?.id
  const questions = apiCache.quiz[courseId]?.[sectionId]

  const updateElapsedTime = () => {
    timeOnPageElapsed.current +=
      new Date().getTime() - timeOnPageStart.current.getTime()
  }

  const updateQuestionsCache = (questionsToCache) => {
    setApiCache({
      ...apiCache,
      quiz: {
        ...apiCache.quiz,
        [courseId]: {
          ...(apiCache.quiz[courseId] || {}),
          [sectionId || 'course']: questionsToCache,
        },
      },
    })
  }

  const getSectionQuestions = async () => {
    setIsLoading(true)

    const questions = await apiCall({
      query: 'getQuestions',
      variables: {
        courseId,
        sectionId,
      },
    }).catch((error) => {
      reportError({ source: 'getQuestions', error })
      setError('Failed to fetch questions')
      return null
    })

    updateQuestionsCache(questions)

    setIsLoading(false)
  }

  const answer = (questionId, answer) => () => {
    setAnswers({ ...answers, [questionId]: answer })
  }

  const submitAnswers = async () => {
    setIsLoading(true)

    updateElapsedTime()

    const result = await apiCall({
      query: 'putAnswers',
      variables: { courseId, sectionId },
      data: {
        answers,
        duration: Math.round(timeOnPageElapsed.current / 1000),
      },
    }).catch((error) => {
      reportError({ source: 'submitAnswers', error })
      setError('Failed to submit answers')
      return null
    })

    if (result) {
      setScore(result.score)
      updateQuestionsCache(result.questions)
    }

    setIsLoading(false)
  }

  const done = async () => {
    if (score === undefined) {
      submitAnswers()
    } else {
      if (score < QUIZ_NOTE_THRESHOLD && section?.hasSupplement) {
        // eslint-disable-next-line no-restricted-globals
        const confirmed = confirm(
          `You scored less than ${QUIZ_NOTE_THRESHOLD}%\nThis section has supplementary material that can help. Would you like to study the supplementary material?`,
        )
        if (confirmed) {
          navigate(`/study/${courseId}/${sectionNumber}/supplement`)
        } else {
          navigate(`/course/${courseId}/`)
        }
      } else {
        navigate(`/course/${courseId}/`)
      }
    }
  }

  useEffect(() => {
    if (user && !course && courseId) {
      getCourseData({ courseId, isEditMode: false })
    }
  }, [user, course, courseId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!questions && course) {
      getSectionQuestions()
    }
  }, [questions, course]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (questions) {
      const resetStartTime = () => {
        timeOnPageStart.current = new Date()
      }

      window.addEventListener('focus', resetStartTime)
      window.addEventListener('blur', updateElapsedTime)

      return () => {
        window.removeEventListener('focus', resetStartTime)
        window.removeEventListener('blur', updateElapsedTime)
      }
    }
  }, [questions])

  return (
    <Layout page={{ title: course ? `"${course.title}" Quiz` : 'Quiz' }}>
      {isLoading && <Loader big />}
      {!isLoading && !!course && (
        <>
          {score === undefined && <ScrollProgress />}
          <Wrapper>
            {!!section && <Subheading>{section.title}</Subheading>}
            {/* {sectionNumber === '0' && (
              <Subheading>Course wide questions</Subheading>
            )} */}
            {!questions?.length && (
              <Paragraph>No questions for this section.</Paragraph>
            )}
            {score !== undefined && (
              <Paragraph>
                You scored {score}% on this quiz. Review explanations for each
                question.
              </Paragraph>
            )}
            {questions?.map((q) => (
              <Question key={q.id}>
                <Paragraph>
                  <strong>{q.question}</strong>
                </Paragraph>
                <AnswerButton
                  $isCorrect={q.correct === 'option1'}
                  $isSelected={answers[q.id] === 'option1'}
                  onClick={answer(q.id, 'option1')}
                  disabled={!!q.correct}
                >
                  {q.option1}
                </AnswerButton>
                <AnswerButton
                  $isCorrect={q.correct === 'option2'}
                  $isSelected={answers[q.id] === 'option2'}
                  onClick={answer(q.id, 'option2')}
                  disabled={!!q.correct}
                >
                  {q.option2}
                </AnswerButton>
                {!!q.option3 && (
                  <AnswerButton
                    $isCorrect={q.correct === 'option3'}
                    $isSelected={answers[q.id] === 'option3'}
                    onClick={answer(q.id, 'option3')}
                    disabled={!!q.correct}
                  >
                    {q.option3}
                  </AnswerButton>
                )}
                {!!q.option4 && (
                  <AnswerButton
                    $isCorrect={q.correct === 'option4'}
                    $isSelected={answers[q.id] === 'option4'}
                    onClick={answer(q.id, 'option4')}
                    disabled={!!q.correct}
                  >
                    {q.option4}
                  </AnswerButton>
                )}
                {q.explanation && (
                  <Paragraph className="explanation">{q.explanation}</Paragraph>
                )}
              </Question>
            ))}
          </Wrapper>
          {!!questions?.length &&
            Object.keys(answers).length === questions?.length && (
              <DoneButton onClick={done}>Done</DoneButton>
            )}
        </>
      )}
    </Layout>
  )
}

const Wrapper = styled.div`
  padding: ${GAP.sm};

  ${MEDIA_QUERY.md} {
    margin: 0 auto;
    max-width: 800px;
  }
`

const Question = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${GAP.sm};
  margin: ${GAP.lg} auto;

  .explanation {
    margin: 0;
    padding: ${GAP.sm};
    border: 1px solid ${COLOR.green};
    border-radius: ${BORDER_RADIUS};
    background-color: ${COLOR.lightGray};
    color: ${COLOR.black};
  }
`

const AnswerButton = styled(Button)`
  text-align: left;

  ${({ $isCorrect, $isSelected }) =>
    $isCorrect
      ? `
          background-color: ${COLOR.green} !important;
          color: ${COLOR.black} !important;
        `
      : $isSelected
        ? `
          background-color: ${COLOR.black} !important;
          color: ${COLOR.white} !important;
        `
        : ''}
`

const DoneButton = styled.button`
  display: block;
  margin: ${GAP.md} auto;
  padding: 20px;
  width: 200px;
  text-align: center;
  font-size: ${TYPOGRAPHY.size.md};
  border: 0;
  border-radius: ${BORDER_RADIUS};
  background-color: ${COLOR.green};
  color: ${COLOR.black};
  appearance: none;
  cursor: pointer;

  &:hover {
    background-color: ${COLOR.blue};
    color: ${COLOR.white};
  }
`

export default Quiz
