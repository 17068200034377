import styled from 'styled-components'
import Layout from 'components/Layout'
import { COLOR, TYPOGRAPHY } from 'ui'

const Error404 = () => {
  return (
    <Layout maxWidth={false}>
      <Content>Page not found</Content>
    </Layout>
  )
}

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: calc(100vh - 38px);
  background-color: ${COLOR.blue};
  color: ${COLOR.white};
  font-size: ${TYPOGRAPHY.size.xxl};
`

export default Error404
