import styled from 'styled-components'

const Grid = styled.div`
  margin: ${({ margin }) => margin || '0'};
  padding: 0;
  display: grid;
  ${({ cols }) => (cols ? `grid-template-columns: ${cols};` : '')}
  ${({ rows }) => (rows ? `grid-template-rows: ${rows};` : '')}
  ${({ gap }) => (gap ? `grid-gap: ${gap};` : '')}
  border-radius: 8px;
`

export default Grid
