import { Chart, registerables } from 'chart.js'
import { useEffect } from 'react'
import styled from 'styled-components'
import Layout from 'components/Layout'
import { Button, COLOR, GAP, Loader } from 'ui'
import { apiCall, capitalize, getRandomColour, htmlToImage } from 'utils'
import { useAppContext } from 'context'

Chart.register(...registerables)

const Topics = () => {
  const { isLoading, setIsLoading, apiCache, setApiCache, setError } =
    useAppContext()
  const topics = apiCache.topics

  const getTopics = async () => {
    setIsLoading(true)

    const fetchedData = await apiCall({
      query: 'getTopics',
    }).catch((error) => {
      reportError({ source: 'getTopics', error })
      setError('Failed to get topics')
      return null
    })

    if (fetchedData) {
      setApiCache({
        ...apiCache,
        topics: fetchedData.sort((a, b) => a.text.localeCompare(b.text)),
      })
    }

    setIsLoading(false)
  }

  const renderChart = () => {
    new Chart(document.getElementById('topics-chart'), {
      options: {
        responsive: true,
        scales: {
          x: {
            title: { display: false, color: COLOR.black, text: 'Topic' },
          },
          y: {
            position: 'left',
            ticks: { stepSize: 1 },
            title: { display: false, color: COLOR.black, text: 'Count' },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      data: {
        labels: topics.map((e) => capitalize(e.text)),
        datasets: [
          {
            type: 'bar',
            data: topics.map((e) => e.count),
            backgroundColor: topics.map(() => getRandomColour()),
          },
        ],
      },
    })
  }

  useEffect(() => {
    if (topics) {
      renderChart()
    } else {
      getTopics()
    }
  }, [topics]) // eslint-disable-line react-hooks/exhaustive-deps

  const menu = [
    ...(!!topics?.length
      ? [
          <Button
            key="exportButton"
            onClick={htmlToImage({
              elementId: 'topics-chart',
              filename: 'Topics',
            })}
          >
            Export
          </Button>,
        ]
      : []),
  ]

  return (
    <Layout page={{ title: 'Topics', menu }}>
      {isLoading ? (
        <Loader big />
      ) : (
        <Wrapper>
          <canvas id="topics-chart" />
          <ul>
            {topics?.map((topic) => (
              <li>
                <b>{capitalize(topic.text)}:</b> {topic.description}
              </li>
            ))}
          </ul>
        </Wrapper>
      )}
    </Layout>
  )
}

const Wrapper = styled.div`
  canvas {
    margin: ${GAP.md} 0;
    padding: 0;
  }

  ul {
    margin: ${GAP.md} 0;
    padding: 0;

    li {
      margin: ${GAP.sm} 0 0 0;
      padding: 0;
    }
  }
`

export default Topics
