import styled from 'styled-components'
import { COLOR, GAP, MEDIA_QUERY, TYPOGRAPHY } from './theme'

const Subheading = styled.h2`
  margin: ${({ margin }) => margin || `0 auto ${GAP.sm} auto`};
  padding: 0;
  width: 100%;
  color: ${COLOR.blue};
  font-size: ${TYPOGRAPHY.size.md};

  ${MEDIA_QUERY.md} {
    font-size: ${TYPOGRAPHY.size.lg};
  }

  &.center {
    margin: ${GAP.md} 0;
    padding: 0 ${GAP.sm};
    color: ${COLOR.black};
    text-align: center;
  }

  &.page-sub {
    margin: ${GAP.md} 0;
    padding: 0 ${GAP.sm};
    color: ${COLOR.black};
  }
`

export default Subheading
