import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Button, Flex, GAP, Grid, Heading, Input } from 'ui'

const ClassModal = ({
  user,
  targetClass,
  deleteClass,
  submitModal,
  closeModal,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm()
  const isAdd = !targetClass

  const handleDelete = (e) => {
    deleteClass(targetClass?.id)(e)
    closeModal(e)
  }

  return (
    <Content>
      <Heading secondary>
        {isAdd ? 'Add a new class' : 'Class settings'}
      </Heading>
      <form onSubmit={handleSubmit(submitModal)}>
        {!isAdd && (
          <Controller
            control={control}
            name="id"
            render={({ field }) => (
              <Input label="Class ID" readOnly={true} {...field} />
            )}
            rules={{ required: 'Class ID is required' }}
            defaultValue={targetClass?.id}
          />
        )}
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <Input label="Title*" error={errors.title} autoFocus {...field} />
          )}
          rules={{ required: 'Class title is required' }}
          defaultValue={targetClass?.title || ''}
        />
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Input isMultiLine={true} label="Description" {...field} />
          )}
          defaultValue={targetClass?.description || ''}
        />
        <Grid cols="45% 45%" gap="10%">
          <div style={{ marginBottom: `-${GAP.md}` }}>
            <Controller
              control={control}
              name="capacity"
              render={({ field }) => (
                <Input
                  type="number"
                  label={`Capacity* (max: ${user.plan.courseCapacity})`}
                  error={errors.capacity}
                  {...field}
                />
              )}
              rules={{
                required: 'Capacity is required',
                validate: (value) =>
                  value <= user.plan.courseCapacity || 'Exceeds max capacity',
              }}
              defaultValue={targetClass?.capacity || user.plan?.courseCapacity}
            />
          </div>
          {/* <Flex dir="column" padding="8px 0 0 0">
            <Controller
              control={control}
              name="isEnrollOpen"
              render={({ field }) => (
                <Checkbox
                  label="Enroll is open"
                  checked={targetClass?.isEnrollOpen}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="isInviteOnly"
              render={({ field }) => (
                <Checkbox
                  label="Enroll by invite only"
                  checked={targetClass?.isInviteOnly}
                  {...field}
                />
              )}
            />
          </Flex> */}
        </Grid>
        <Flex justifyContent="space-between" margin={`${GAP.lg} 0 0 0`}>
          <div>
            {!isAdd && deleteClass && (
              <Button onClick={handleDelete}>Delete</Button>
            )}
          </div>
          <div>
            <Button onClick={closeModal} margin={`0 ${GAP.sm} 0 0 `}>
              Cancel
            </Button>
            <Button
              type="submit"
              primary
              disabled={(!isAdd && !isDirty) || isSubmitting}
            >
              {isAdd ? 'Create' : 'Update'}
            </Button>
          </div>
        </Flex>
      </form>
    </Content>
  )
}

const Content = styled.div`
  position: relative;
  margin: 0;
  padding: ${GAP.md};
`

export default ClassModal
