import Account from 'pages/Account'
import Course from 'pages/Course'
import Dashboard from 'pages/Dashboard'
import Edit from 'pages/Edit'
// import Enroll from 'pages/Enroll'
import Error404 from 'pages/Error/404'
// import Home from 'pages/Home'
import Quiz from 'pages/Quiz'
import Study from 'pages/Study'
import StudyPattern from 'pages/StudyPattern'
import Topics from 'pages/Topics'
// import UIdemo from 'pages/UIdemo'
import { useEffect, useState } from 'react'
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom'
import GlobalStyles from 'components/GlobalStyles'
import { viewportUnfocusClass } from 'components/Layout'
import { modalOpenClass } from 'components/Modal'
import { apiCall, hasToken, parseParamString, reportError } from 'utils'
import { AppContext } from 'context'
import {
  MAIN_ROUTE,
  SIGNIN_REDIRECT_STORAGE,
  SIGNIN_URL,
  SIGNOUT_URL, // SIGNUP_URL,
  TOKEN_STORAGE,
} from 'config'

export const Redirect = ({ to }) => {
  if (to.startsWith('http')) {
    window.location = to
    return null
  }
  return <Navigate to={to} />
}

const SigninHandler = () => {
  const { hash } = useLocation()
  const { idToken } = parseParamString(hash)
  localStorage.setItem(TOKEN_STORAGE, idToken)
  const redirectTo = localStorage.getItem(SIGNIN_REDIRECT_STORAGE)
  if (redirectTo) localStorage.removeItem(SIGNIN_REDIRECT_STORAGE)
  return <Redirect to={redirectTo || MAIN_ROUTE} />
}

const SignoutHandler = () => {
  if (hasToken()) {
    localStorage.removeItem(TOKEN_STORAGE)
  }
  return <Redirect to={SIGNOUT_URL} />
}

const routes = [
  {
    path: '/',
    exact: true,
    // element: hasToken() ? <Redirect to={MAIN_ROUTE} /> : <Home />,
    // element: <Home />,
    element: <div />,
  },
  // { path: '/ui-demo', element: <UIdemo /> },
  // { path: '/signup', element: <Redirect to={SIGNUP_URL} /> },
  { path: '/signin', exact: true, element: <Redirect to={SIGNIN_URL} /> },
  { path: '/signin/redirect', element: <SigninHandler /> },
  { path: '/signout', exact: true, element: <SignoutHandler /> },
  { path: '/signout/redirect', element: <h1>You've signed out.</h1> },
  {
    path: MAIN_ROUTE,
    element: <Dashboard />,
    authRequired: true,
  },
  {
    path: '/account',
    element: <Account />,
    authRequired: true,
  },
  {
    path: '/pattern',
    element: <StudyPattern />,
    authRequired: true,
  },
  {
    path: '/topics',
    element: <Topics />,
    authRequired: true,
  },
  {
    path: '/edit/:courseId/:sectionNumber/supplement?',
    element: <Edit />,
    authRequired: true,
  },
  {
    path: '/study/:courseId/:sectionNumber/supplement?',
    element: <Study />,
    authRequired: true,
  },
  {
    path: '/course/:courseId/',
    element: <Course />,
    authRequired: true,
  },
  {
    path: '/quiz/:courseId',
    exact: true,
    element: <Quiz />,
    authRequired: true,
  },
  {
    path: '/quiz/:courseId/:sectionNumber',
    element: <Quiz />,
    authRequired: true,
  },
  // {
  //   path: '/enroll/:courseId',
  //   element: <Enroll />,
  //   authRequired: true,
  // },
  // {
  //   path: '/invite/:sourceId/:inviteId',
  //   element: <Invite />,
  //   authRequired: true,
  // },
]

const renderRouteElement = ({
  authRequired,
  // path,
  element,
  // user,
}) => {
  if (authRequired && !hasToken()) {
    return <Redirect to={SIGNIN_URL} />
  }
  // if (authRequired && !user.isEnabled && path !== '/user') {
  //   return <Redirect to='/user' />
  // }
  // if (
  //   authRequired &&
  //   user.isEnabled &&
  //   !user.subscription &&
  //   path !== '/subscription'
  // ) {
  //   return <Redirect to='/subscription' />
  // }
  // if (
  //   authRequired &&
  //   user.isEnabled &&
  //   !user.subscription?.isActive &&
  //   !['/subscription', '/dashboard', '/profile'].includes(path)
  // ) {
  //   return <Redirect to='/subscription' />
  // }
  return element
}

const App = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()
  const [modals, setModals] = useState([])
  const [user, setUser] = useState()
  const [courses, setCourses] = useState()
  const [enrolls, setEnrolls] = useState()
  // const [notifications, setNotifications] = useState()
  const [apiCache, setApiCache] = useState({
    course: {},
    content: {},
    question: {},
    quiz: {},
    stats: {},
    studyPattern: null,
    topics: null,
    // notifications: [],
  })

  const getUserData = async () => {
    setIsLoading(true)
    try {
      const data = await apiCall({ query: 'getUserData' })
      if (data) {
        if (data.user.isEnabled) {
          setUser(data.user)
          setCourses(data.courses)
          setEnrolls(data.enrolls)
        } else {
          reportError({ error: 'Resticted.' })
          setUser({ isEnabled: false })
          setCourses([])
          setEnrolls([])
        }
      } else {
        reportError({ error: 'No user data' })
        setError('Failed to fetch user')
        setUser({})
        setCourses([])
        setEnrolls([])
      }
    } catch (error) {
      reportError({ error })
    }
    setIsLoading(false)
  }

  const getCourseData = async ({ courseId, sectionId, isEditMode = false }) => {
    setIsLoading(true)
    try {
      const query = isEditMode ? 'getCourse' : 'getCourseInfo'
      const data = await apiCall({
        query,
        variables: { courseId },
      })
      setApiCache({
        ...apiCache,
        course: {
          ...apiCache.course,
          [courseId]: data,
        },
        ...(sectionId
          ? {
              content: {
                ...apiCache.content,
                [courseId]: {
                  [sectionId]: null,
                  [`${sectionId}-supplement`]: null,
                },
              },
            }
          : {}),
      })
    } catch (error) {
      reportError({ error })
      setError('Failed to fetch course')
    }
    setIsLoading(false)
  }

  // const getNotifications = async () => {
  //   setIsLoading(true)
  //   try {
  //     const data = await apiCall({ query: 'getNotifications' })
  //     setNotifications(data)
  //   } catch (error) {
  //     reportError({ error })
  //   }
  //   setIsLoading(false)
  // }

  const openModal = (modal) => {
    setModals([...modals, modal])
    const viewport = document.getElementById('viewport')
    viewport.classList.add(viewportUnfocusClass)
    setTimeout(() => {
      const modalElement = document.getElementById(`modal-${modal.id}`)
      modalElement.classList.add(modalOpenClass)
    }, 100)
  }

  const closeModal = (modalId) => {
    const openModals = modals.filter((e) => e.id !== modalId)
    if (!openModals.length) {
      const viewport = document.getElementById('viewport')
      viewport.classList.remove(viewportUnfocusClass)
    }
    const modalElement = document.getElementById(`modal-${modalId}`)
    modalElement.classList.remove(modalOpenClass)
    setTimeout(() => {
      setModals(openModals)
    }, 500)
  }

  const closeAllModals = () => {
    const viewport = document.getElementById('viewport')
    viewport.classList.remove(viewportUnfocusClass)
    modals.forEach((e) => {
      const modalElement = document.getElementById(`modal-${e.id}`)
      modalElement.classList.remove(modalOpenClass)
    })
    setTimeout(() => {
      setModals([])
    }, 500)
  }

  const context = {
    isLoading,
    setIsLoading,
    error,
    setError,
    modals,
    setModals,
    openModal,
    closeModal,
    closeAllModals,
    user,
    setUser,
    getUserData,
    courses,
    setCourses,
    getCourseData,
    enrolls,
    setEnrolls,
    notifications: [],
    // setNotifications,
    // getNotifications,
    apiCache,
    setApiCache,
  }

  useEffect(() => {
    if (hasToken()) {
      Promise.all([
        getUserData(),
        // getNotifications()
      ])
    }
  }, [])

  return (
    <AppContext.Provider value={context}>
      <GlobalStyles />
      <Router>
        <Routes>
          {routes.map(
            ({ path, exact = false, element, authRequired = false }) => (
              <Route
                key={path}
                path={path}
                {...(exact ? { exact } : {})}
                element={renderRouteElement({
                  authRequired,
                  path,
                  element,
                  user,
                })}
              />
            ),
          )}
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  )
}

export default App
