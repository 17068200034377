import styled from 'styled-components'
import { BORDER_RADIUS, COLOR, Flex, GAP, MEDIA_QUERY, Subheading } from 'ui'

const Column = ({ title, button, x, children }) => {
  return (
    <Wrapper>
      <Header>
        <Flex justifyContent="spaced-between" alignItems="center">
          <Title>{title}</Title>
          {button && <Button onClick={button}>{x ? 'x' : '+'}</Button>}
        </Flex>
      </Header>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: ${GAP.sm};
  padding: 0;
  transition: 0.3s all;

  ${MEDIA_QUERY.md} {
    margin: ${GAP.md};
  }

  &:hover {
    background-color: ${COLOR.lightGray};
    border-radius: ${BORDER_RADIUS};
  }
`

const Header = styled.div`
  margin: 0;
  padding: ${GAP.sm};
  background-color: ${COLOR.lightGray};
  border-top-left-radius: ${BORDER_RADIUS};
  border-top-right-radius: ${BORDER_RADIUS};
`

const Title = styled(Subheading)`
  margin: 0;
  padding: 0;
  color: ${COLOR.black};
`

const Button = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  width: 20px;
  height: 20px;
  line-height: 18px;
  border-radius: 3px;
  background-color: ${COLOR.black};
  color: ${COLOR.white};
  font-size: 18px;
  cursor: pointer;
`

export default Column
