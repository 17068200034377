import { cloneElement, useEffect } from 'react'
import styled from 'styled-components'
import { COLOR, MEDIA_QUERY } from 'ui'
import { useAppContext } from 'context'

const Modal = ({ modal }) => {
  const {
    modals,
    closeAllModals,
    closeModal: closeModalAction,
  } = useAppContext()
  const isFirstModal = modals.findIndex((e) => e.id === modal.id) === 0

  const onBackdropClick = () => {
    closeAllModals()
  }

  const closeModal = (e) => {
    e.preventDefault()
    closeModalAction(modal.id)
  }

  const submitModal = (data) => {
    modal.onSubmit(data)
    closeModalAction(modal.id)
  }

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
      }
      if (e.key === 'Escape') {
        // this closes all modals since they're all listening
        closeModalAction(modal.id)
      }
    }

    document.addEventListener('keypress', onKeyPress)

    return () => {
      document.removeEventListener('keypress', onKeyPress)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper id={`modal-${modal.id}`}>
      <Backdrop $isFirstModal={isFirstModal} onClick={onBackdropClick} />
      <Content>
        {cloneElement(modal.content, { submitModal, closeModal })}
      </Content>
    </Wrapper>
  )
}

export const modalOpenClass = 'open'

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ $isFirstModal }) =>
    $isFirstModal ? 'rgba(0, 0, 0, 0.7)' : 'transparent'};
  transition: 0.5s opacity;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;

  .open & {
    opacity: 1;
    pointer-events: all;
  }
`

const Content = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 90vw;
  height: 100vh;
  background-color: ${COLOR.white};
  box-shadow:
    rgba(60, 64, 67, 0.3) -1px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) -1px 1px 3px 1px;
  transition: 0.5s transform cubic-bezier(0.77, 0.2, 0.05, 1);
  transform: translateX(110%);
  overflow-x: hidden;
  overflow-y: auto;

  .open & {
    transform: translateX(0);
  }

  ${MEDIA_QUERY.md} {
    width: 50vw;
  }
`
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;

  &.open {
    pointer-events: all;
  }
`

export default Modal
