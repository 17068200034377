import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Button, Flex, GAP, Heading, Input, Select } from 'ui'
import { isEmail } from 'utils'

const formatEmailsValue = (input) => input.split(',').map((e) => e.trim())

const InviteLearnerModal = ({
  course,
  classOptions,
  submitModal,
  closeModal,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (formData) => {
    formData.classId = formData.classId.value
    formData.learnerEmails = formatEmailsValue(formData.learnerEmails)
    submitModal(formData)
  }

  return (
    <Content>
      <Heading secondary>Invite learners</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="courseId"
          render={({ field }) => <Input type="hidden" {...field} />}
          defaultValue={course.id}
        />
        <Input label="Course*" readOnly={true} defaultValue={course.title} />
        <Controller
          control={control}
          name="classId"
          render={({ field }) => (
            <Select
              label="Class*"
              options={classOptions}
              error={errors.course}
              {...field}
            />
          )}
          rules={{ required: 'Class is required' }}
          defaultValue={classOptions[0]}
        />
        <Controller
          control={control}
          name="learnerEmails"
          render={({ field }) => (
            <Input
              isMultiLine={true}
              label="Learner Emails* (separate with comma if more than one)"
              error={errors.learnerEmails}
              {...field}
            />
          )}
          rules={{
            required: 'Valid learner email is required',
            validate: (value) => {
              return (
                formatEmailsValue(value).every((v) => isEmail(v)) ||
                'Valid learner email is required'
              )
            },
          }}
          defaultValue=""
        />
        <Flex justifyContent="flex-end" margin={`${GAP.lg} 0 0 0`}>
          <Button onClick={closeModal} margin={`0 ${GAP.sm} 0 0 `}>
            Cancel
          </Button>
          <Button type="submit" primary>
            Invite
          </Button>
        </Flex>
      </form>
    </Content>
  )
}

const Content = styled.div`
  position: relative;
  margin: 0;
  padding: ${GAP.md};
`

export default InviteLearnerModal
