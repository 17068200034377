import styled from 'styled-components'
import { Checkmark } from './Icon'
import { COLOR, GAP, TYPOGRAPHY } from './theme'

const Checkbox = ({ label, checked, margin, ...props }) => (
  <Wrapper $margin={margin}>
    <label>
      <CheckboxInput
        type="checkbox"
        {...props}
        {...(checked ? { defaultChecked: checked } : {})}
      />
      <Box>{Checkmark}</Box>
      <Label>{label}</Label>
    </label>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  margin: ${({ $margin }) => $margin || `0 0 ${GAP.xs} 0`};
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    cursor: pointer;
  }
`

const CheckboxInput = styled.input`
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  outline: none;

  &:focus + div {
    border-color: ${COLOR.green};
    background-color: ${COLOR.green};
  }

  &:checked + div {
    border-color: ${COLOR.blue};
    background-color: ${COLOR.blue};

    svg {
      transform: translateY(1px) scale(0.7);
    }
  }
`

const Box = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 0 ${GAP.xs} 0 0;
  padding: 0;
  width: 20px;
  line-height: 18px;
  border: 1px solid ${COLOR.blue};
  border-radius: 4px;
  background-color: ${COLOR.white};
  transition: 0.3s all;

  svg {
    transform: translateY(1px) scale(0);
    transition: 0.3s all;

    path {
      fill: ${COLOR.white};
    }
  }
`

const Label = styled.span`
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  color: ${COLOR.black};
  font-size: ${TYPOGRAPHY.size.sm};
`

export default Checkbox
