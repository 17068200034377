import styled from 'styled-components'
import { Button, COLOR, GAP, MAX_WIDTH, Paragraph } from 'ui'
import { useAppContext } from 'context'

const ErrorBanner = () => {
  const { error, setError } = useAppContext()

  const ok = () => {
    setError(null)
  }

  return (
    <Wrapper $isOpen={!!error}>
      <Inner>
        <Paragraph>{error}</Paragraph>
        <Button onClick={ok}>OK</Button>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  background-color: ${COLOR.alert};
  transition: 0.3s all;
  z-index: 2;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translate3d(0, 1px, 0)' : 'translate3d(0, 110%, 0)'};
`

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${GAP.sm};
  padding: ${GAP.sm} 0;
  width: 100%;
  max-width: ${MAX_WIDTH};

  p {
    margin: 0 ${GAP.sm} 0 0;
    color: ${COLOR.white};
    font-weight: 600;
  }

  button:hover {
    background-color: ${COLOR.black};
    border-color: ${COLOR.black};
  }
`

export default ErrorBanner
