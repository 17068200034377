import axios from 'axios'
import html2canvas from 'html2canvas'
import { throttle } from 'lodash'
import tinycolor from 'tinycolor2'
import { COLOR } from 'ui'
import {
  API_URL,
  BANNER_STORAGE,
  IS_PROD,
  SIGNIN_REDIRECT_STORAGE,
  SIGNIN_URL,
  TOKEN_STORAGE,
} from 'config'

export const hasToken = () => localStorage.getItem(TOKEN_STORAGE) !== null

export const hasBannerOk = () => localStorage.getItem(BANNER_STORAGE) !== null

export const reportError = ({ source = 'app', error }) => {
  // if (!IS_PROD) console.error(`>> ${source}:`, error)
  console.error(`>> ${source}:`, error)
}

export const checkCourseOwnership = ({ user, courseId }) => {
  return user?.courseIds.includes(courseId)
}

export const checkCourseEnrollment = ({ user, courseId }) => {
  return !!user?.enrollIds[courseId]
}

export const isEmail = (string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    string,
  )

export const secondsToMinutes = (seconds) => {
  return Math.round(seconds / 60)
}

export const htmlToImage =
  ({ elementId, filename }) =>
  () => {
    const element = document.getElementById(elementId)
    if (!element) return
    const link = document.createElement('a')
    html2canvas(element, { logging: false }).then((canvas) => {
      link.href = canvas.toDataURL()
      link.download = `${filename}_${new Date().valueOf()}.jpg`.replace(
        / /g,
        '-',
      )
      link.click()
    })
  }

export const htmlToText = (html) => {
  const temp = document.createElement('div')
  temp.innerHTML = html
  return temp.innerText
}

export const apiCall = async ({
  query,
  variables,
  data,
  authRequired = true,
}) => {
  let token = null
  if (authRequired) {
    token = localStorage.getItem(TOKEN_STORAGE)
    if (!token) throw new Error('Unauthorized.')
  }
  if (!query) throw new Error('Malformed API call.')
  return axios
    .post(
      API_URL,
      { query, variables, data },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    .then((result) => result?.data?.data || null)
    .catch((error) => {
      const isUnAuthError = error.response.data.error === 'Unauthorized.'
      const isAuthExpiredError = error.response.data.error === 'Auth expired.'
      if (isUnAuthError) {
        throw new Error('Unauthorized.')
      } else if (isAuthExpiredError && authRequired) {
        localStorage.setItem(SIGNIN_REDIRECT_STORAGE, window.location.pathname)
        setTimeout(() => {
          window.location = SIGNIN_URL
        }, 200)
      } else {
        throw error
      }
    })
}

export const gql = async ({ query, variables }) => {
  const token = localStorage.getItem(TOKEN_STORAGE)
  if (!token) throw new Error('Unauthorized.')
  return axios
    .post(
      API_URL,
      { query, variables },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    .then(({ data }) => data)
    .catch((error) => {
      console.log('gql error =', error)
    })
}

export const sendEvent = async ({ label, variables, payload }) => {
  if (!IS_PROD) return
  if (!label || !variables) {
    throw new Error('Malformed event was provided.')
  }
  return apiCall({
    query: 'putEvent',
    variables: { ...variables, label },
    data: payload,
  })
}

export const getRandomIndex = (length) => Math.floor(Math.random() * length)

export const getRandomColour = () => {
  const letters = '0123456789ABCDEF'.split('')
  let colour = '#'
  for (let i = 0; i < 6; i++) {
    colour += letters[getRandomIndex(16)]
  }
  return colour
}

export const getRandomPastelColour = () => {
  // const pastelColours = [
  //   '#9BF6FF',
  //   '#CAFFBF',
  //   '#FDFFB6',
  //   '#FFD6A5',
  //   '#FFADAD',
  //   '#EFEFEF',
  //   '#B1E6F3',
  //   '#72DDF7',
  //   '#79B8F4',
  //   '#8093F1',
  //   '#F1F5D8',
  //   '#D6FFC1',
  //   '#B9FFAF',
  //   '#97FA9A',
  //   '#8AF0BF',
  //   '#F7ADC3',
  //   '#FCC5D9',
  //   '#FADDE3',
  //   '#F7F5ED',
  //   '#72DDF7',
  //   '#957DAD',
  //   '#E0BBe4',
  //   '#FEC8D8',
  //   '#FFDFD3',
  //   '#FFC4C4',
  //   '#CBC7DD',
  //   '#FFDFBD',
  //   '#F8FFEB',
  //   '#BAEEE5',
  //   '#DFC5E8',
  //   '#FFFAB0',
  //   '#CBF2B8',
  // ]
  // const randomIndex = getRandomIndex(pastelColours.length)
  // return pastelColours[randomIndex]
  const r = Math.floor(Math.random() * 127 + 127) // Random value between 127 and 255
  const g = Math.floor(Math.random() * 127 + 127) // Random value between 127 and 255
  const b = Math.floor(Math.random() * 127 + 127) // Random value between 127 and 255
  return `rgb(${r}, ${g}, ${b})`
}

export const darkenColour = (colour) => tinycolor(colour).darken().toString()

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const snakeToCamelCase = (string) =>
  string
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    )

export const percentage = ({ value, total }) =>
  Math.round((value / total) * 100)

export const parseParamString = (paramString) =>
  paramString
    .replace('?', '')
    .replace('#', '')
    .split('&')
    .map((v) => v.split('='))
    .reduce(
      (pre, [key, value]) => ({
        ...pre,
        [snakeToCamelCase(key)]: value,
      }),
      {},
    )

export const formatChartData = (data) => {
  const labels = [] // TODO: All days in selected month
  const values = []
  data.forEach((entry) => {
    labels.push(entry.value)
    values.push(entry.count)
  })
  return {
    labels,
    datasets: [
      {
        label: 'Total events per days on record',
        data: values,
        backgroundColor: COLOR.green,
      },
    ],
  }
}

export const lexicalToHtml = (editor) => {
  const cloneElementWithStyles = (el) => {
    const clone = document.createElement(el.tagName)
    clone.innerHTML = el.innerHTML
    const s = el.style // getComputedStyle(el);
    for (let key in s) {
      let prop = key.replace(/-([a-z])/g, (v) => v[1].toUpperCase())
      try {
        clone.style[prop] = s[key]
      } catch {}
    }
    return clone
  }

  const formatElement = (el) => {
    const clone = cloneElementWithStyles(el)
    const children = Array.from(clone.children)
    if (children.length) {
      clone.innerHTML = ''
      children.forEach((child) => clone.append(formatElement(child)))
    }
    return clone
  }

  const tempEl = document.createElement('div')
  tempEl.append(formatElement(editor._rootElement))
}

export class ScrollDepth {
  constructor(settings) {
    this.settings = {
      throttle: 500,
      scrollElement: document.documentElement,
      percentages: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      pixelDepthInterval: 0,
      elements: [],
      percentageDepthLabel: 'scroll',
      pixelDepthLabel: 'pixelDepth',
      elementLabel: 'elementInView',
      onEvent: () => {},
      onScroll: () => {},
      ...settings,
    }
    this.greatestScrollTop = 0
  }

  percentageDepth() {
    const scrollRatio =
      this.settings.scrollElement.scrollTop /
      (this.settings.scrollElement.scrollHeight -
        this.settings.scrollElement.clientHeight)
    this.settings.percentages.forEach((point, index) => {
      if (scrollRatio * 100 >= point) {
        this.settings.percentages.splice(index, 1)
        this.settings.onEvent({
          label: this.settings.percentageDepthLabel,
          payload: point,
        })
      }
    })
    if (this.settings.onScroll) {
      this.settings.onScroll(scrollRatio)
    }
  }

  pixelDepth() {
    const scrollTop = this.settings.scrollElement.scrollTop
    while (
      scrollTop >=
      this.greatestScrollTop + this.settings.pixelDepthInterval
    ) {
      this.greatestScrollTop += this.settings.pixelDepthInterval
      this.settings.onEvent({
        label: this.settings.pixelDepthLabel,
        payload: this.greatestScrollTop + this.settings.pixelDepthInterval,
      })
    }
  }

  elements() {
    this.settings.elements.forEach((elementId, index) => {
      const element = document.getElementById(elementId)
      if (
        element &&
        element.offsetTop &&
        element.clientHeight &&
        element.offsetTop + element.clientHeight <
          this.settings.scrollElement.clientHeight +
            this.settings.scrollElement.scrollTop
      ) {
        this.settings.elements.splice(index, 1)
        this.settings.onEvent({
          label: this.settings.elementLabel,
          payload: elementId,
        })
      }
    })
  }

  onScroll() {
    if (this.settings.percentages) {
      this.percentageDepth()
    }
    if (this.settings.pixelDepthInterval) {
      this.pixelDepth()
    }
    if (this.settings.elements.length) {
      this.elements()
    }
  }

  stop() {
    window.removeEventListener('scroll', this.watch, true)
  }

  start() {
    this.watch = this.settings.throttle
      ? throttle(this.onScroll.bind(this), this.settings.throttle)
      : this.onScroll.bind(this)
    window.addEventListener('scroll', this.watch, true)
  }
}
