// import { useState } from 'react'
import styled from 'styled-components'
import PageHeader from 'components/PageHeader'
import { COLOR, GAP, TYPOGRAPHY } from 'ui'
import { SITE_TITLE } from 'config'

const Header = ({ page, ...props }) => {
  // const [isMenuOpen, setIsMenuOpen] = useState(false)

  // const toggleMenu = () => {
  //   setIsMenuOpen((prev) => !prev)
  // }

  return (
    <Wrapper {...props}>
      <GlobalHeader $isExtended={false}>
        <GlobalHeaderInner>
          <Title>{SITE_TITLE}</Title>
          {/* <MenuButton onClick={toggleMenu}>{Icon.Menu}</MenuButton> */}
        </GlobalHeaderInner>
        {/* <GlobalHeaderMenu>
          <Button>Menu Item</Button>
          <Button>Menu Item</Button>
          <Button>Menu Item</Button>
        </GlobalHeaderMenu> */}
      </GlobalHeader>
      {page && <PageHeader {...page} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  z-index: 99;
`

const GlobalHeader = styled.div`
  margin: 0;
  padding: ${GAP.xs} ${GAP.sm};
  width: 100%;
  background-color: ${COLOR.black};
  overflow: hidden;
  transition: height 0.3s;
  /* display: flex; */

  /* ${({ $isExtended }) =>
    $isExtended ? 'height: 200px;' : 'height: 45px;'} */
`

const GlobalHeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
`

const Title = styled.span`
  margin: 0;
  padding: 0;
  font-size: ${TYPOGRAPHY.size.sm};
  color: ${COLOR.green};
  user-select: none;
`

// const GlobalHeaderMenu = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-end;
//   gap: ${GAP.xs};
//   margin: 0;
//   padding: ${GAP.xs} 0;
// `

// const MenuButton = styled.button`
//   margin: 0;
//   padding: 0;
//   width: 25px;
//   height: 25px;
//   text-align: center;
//   appearance: none;
//   border: 0;
//   background-color: ${COLOR.black};
//   border-radius: 8px;
//   cursor: pointer;

//   svg {
//     transform: scale(0.8);
//   }
// `

export default Header
