import { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ClassModal from 'components/ClassModal'
import {
  BORDER_RADIUS,
  Box,
  Button,
  COLOR,
  Flex,
  GAP,
  Heading,
  Loader,
  Paragraph,
  Subheading,
  TYPOGRAPHY,
} from 'ui'
import { apiCall } from 'utils'
import { useAppContext } from 'context'

const ClassesModal = ({
  courseId,
  closeModal,
  resetCharts,
  isEditMode = false,
}) => {
  // const navigate = useNavigate()
  const { setError, openModal, getCourseData, apiCache, user } = useAppContext()
  const [isLoading, setIsLoading] = useState(false)
  const course = apiCache.course[courseId]
  const classes = course?.classes || []

  const openClassModal = (targetClassId) => {
    openModal({
      id: 'addClass',
      onSubmit: saveClass,
      content: (
        <ClassModal
          user={user}
          targetClass={
            targetClassId
              ? classes.find((c) => c.id === targetClassId)
              : undefined
          }
          deleteClass={
            classes.length > 1 && targetClassId ? deleteClass : undefined
          }
        />
      ),
    })
  }

  const saveClass = async (classData) => {
    setIsLoading(true)

    const success = await apiCall({
      query: 'putClass',
      variables: {
        courseId,
        ...(classData.id ? { classId: classData.id } : {}),
      },
      data: classData,
    }).catch((error) => {
      reportError({ source: 'saveClass', error })
      setError('Failed to save class')
      return false
    })

    if (success) {
      await getCourseData({ courseId, isEditMode })
      resetCharts?.()
    }

    setIsLoading(false)
  }

  const deleteClass = (classId) => async (e) => {
    e?.preventDefault()

    const confirmed = confirm('Delete class?') // eslint-disable-line no-restricted-globals
    if (!confirmed || !classId) return

    setIsLoading(true)

    const success = await apiCall({
      query: 'deleteClass',
      variables: {
        courseId,
        classId,
      },
    }).catch((error) => {
      reportError({ source: 'deleteClass', error })
      setError('Failed to delete class')
      return false
    })

    if (success) {
      await getCourseData({ courseId, isEditMode })
      resetCharts?.()
    }

    setIsLoading(false)
  }

  return (
    <Box>
      <Flex justifyContent="space-between" margin={`0 0 ${GAP.md} 0`}>
        <Heading secondary margin="0">
          Manage classes
        </Heading>
        <Flex justifyContent="flex-end">
          {isEditMode && !isLoading && (
            <Button
              primary
              onClick={() => {
                openClassModal()
              }}
              margin={`0 ${GAP.xs} 0 0`}
            >
              Add
            </Button>
          )}
          <Button onClick={closeModal}>Close</Button>
        </Flex>
      </Flex>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!classes?.length && <Paragraph>No classes added yet.</Paragraph>}
          {classes?.length > 0 && (
            <Small>Total classes: {classes.length}</Small>
          )}
          {classes?.map((c) => (
            <ClassBox key={c.id}>
              <Subheading>{c.title}</Subheading>
              <Paragraph>{c.description}</Paragraph>
              <Flex justifyContent="flex-end" gap={GAP.xs}>
                {isEditMode ? (
                  <Button
                    primary
                    onClick={() => {
                      openClassModal(c.id)
                    }}
                  >
                    Settings
                  </Button>
                ) : (
                  <Button onClick={() => {}}>Enroll</Button>
                )}
              </Flex>
            </ClassBox>
          ))}
        </>
      )}
    </Box>
  )
}

const ClassBox = styled(Box)`
  background-color: ${COLOR.lightGray};
  margin-bottom: ${GAP.md};
  padding: ${GAP.sm};
  border-radius: ${BORDER_RADIUS};

  button {
    padding: ${GAP.xxs} ${GAP.xs};
    font-size: ${TYPOGRAPHY.size.xs};
  }
`

const Small = styled(Paragraph)`
  font-size: ${TYPOGRAPHY.size.xs};
  font-style: italic;
`

export default ClassesModal
