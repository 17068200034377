import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Layout from 'components/Layout'
import { LexicalEditor } from 'components/LexicalEditor'
import SectionsModal from 'components/SectionsModal'
import {
  BORDER_RADIUS,
  Button,
  COLOR,
  GAP,
  Loader,
  ScrollProgress,
  Subheading,
  TYPOGRAPHY,
} from 'ui'
import { apiCall, sendEvent } from 'utils'
import { useAppContext } from 'context'

const STUDY_DURATION_THRESHOLD = 30

const Study = () => {
  const navigate = useNavigate()
  const {
    user,
    setError,
    openModal,
    isLoading,
    setIsLoading,
    getCourseData,
    apiCache,
    setApiCache,
  } = useAppContext()
  const { courseId, sectionNumber = '1' } = useParams()
  const location = useLocation()
  const isSupplement = location.pathname.endsWith('/supplement')
  const [isLoadingContent, setIsLoadingContent] = useState(true)
  const [noContent, setNoContent] = useState(
    'There is no content for this section.',
  )
  const timeOnPageStart = useRef(new Date())
  const timeOnPageElapsed = useRef(0)
  const studyIsDone = useRef(false)
  const course = apiCache.course[courseId]
  const sections = course?.sections || []
  const section = sections.find((c) => c.number === Number(sectionNumber))
  const sectionId = section?.id
  const sectionCacheKey = isSupplement ? `${sectionId}-supplement` : sectionId
  const contentJson = apiCache.content[courseId]?.[sectionCacheKey]
  const contentJsonString = [undefined, null].includes(contentJson)
    ? contentJson
    : JSON.stringify(contentJson)
  const eventUser = useMemo(
    () => (courseId && sectionId && user ? { courseId, sectionId } : null),
    [courseId, sectionId, user],
  )

  const goToCourse = () => {
    navigate(`/course/${courseId}/`)
  }

  const goToSection = () => {
    navigate(`/study/${courseId}/${sectionNumber}/`)
  }

  const goToSupplement = () => {
    navigate(`/study/${courseId}/${sectionNumber}/supplement`)
  }

  const updateElapsedTime = () => {
    timeOnPageElapsed.current +=
      new Date().getTime() - timeOnPageStart.current.getTime()
  }

  const openSectionsModal = () => {
    openModal({
      id: 'sections',
      content: <SectionsModal courseId={courseId} />,
    })
  }

  const getSectionContent = async () => {
    setIsLoadingContent(true)

    if (contentJson !== undefined) {
      setTimeout(() => {
        setIsLoadingContent(false)
      }, 10)
      return
    }

    setIsLoading(true)

    const savedContent = await apiCall({
      query: 'getSectionContent',
      variables: {
        courseId,
        sectionId,
      },
      data: {
        isPublish: true,
        isSupplement,
      },
    }).catch((error) => {
      if (error.message === 'Unauthorized.') {
        setNoContent('You are not enrolled in this course.')
      } else {
        setNoContent('There is no content for this section.')
      }
      setError('Failed to fetch content')
      return null
    })

    setApiCache({
      ...apiCache,
      content: {
        ...apiCache.content,
        [courseId]: {
          ...(apiCache.content[courseId] || {}),
          [sectionCacheKey]: savedContent,
        },
      },
    })

    setIsLoading(false)
    setIsLoadingContent(false)
  }

  const getSectionQuestions = async () => {
    let questions = apiCache.question[courseId]?.[sectionId]

    if (!questions) {
      setIsLoading(true)

      questions = await apiCall({
        query: 'getQuestions',
        variables: {
          courseId,
          sectionId,
        },
      }).catch((error) => {
        reportError({ source: 'getQuestions', error })
        setError('Failed to fetch questions')
        return null
      })

      setApiCache({
        ...apiCache,
        quiz: {
          ...apiCache.quiz,
          [courseId]: {
            ...(apiCache.quiz[courseId] || {}),
            [sectionId]: questions,
          },
        },
      })

      setIsLoading(false)
    }

    return questions
  }

  const done = async () => {
    studyIsDone.current = true
    updateElapsedTime()
    const duration = Math.round(timeOnPageElapsed.current / 1000)

    const [questions] = await Promise.all([
      getSectionQuestions(),
      ...(duration >= STUDY_DURATION_THRESHOLD
        ? [
            sendEvent({
              label: 'study',
              variables: eventUser,
              payload: { duration, isSupplement, isDone: true },
            }),
          ]
        : []),
    ])

    if (questions?.length) {
      const goToQuiz = confirm('Take a quiz?') // eslint-disable-line no-restricted-globals
      if (goToQuiz) {
        setApiCache({
          ...apiCache,
          quiz: {
            ...apiCache.quiz,
            [courseId]: {
              ...(apiCache.quiz[courseId] || {}),
              [sectionId]: apiCache.quiz[courseId]?.[sectionId]?.map((e) => {
                delete e.correct
                delete e.explanation
                return e
              }),
            },
          },
        })
        navigate(`/quiz/${courseId}/${sectionNumber}`)
      } else {
        goToCourse()
      }
    } else {
      goToCourse()
    }
  }

  useEffect(() => {
    if (user && !course && courseId) {
      getCourseData({ courseId, isEditMode: false })
    }
  }, [user, course, courseId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sectionId) {
      getSectionContent()
    }
  }, [sectionId, isSupplement]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (contentJson && eventUser) {
      const resetStartTime = () => {
        timeOnPageStart.current = new Date()
      }

      const sendTime = () => {
        if (studyIsDone.current) return
        updateElapsedTime()
        const duration = Math.round(timeOnPageElapsed.current / 1000)
        if (duration >= STUDY_DURATION_THRESHOLD) {
          sendEvent({
            label: 'study',
            variables: eventUser,
            payload: { duration },
          })
        }
      }

      window.addEventListener('focus', resetStartTime)
      window.addEventListener('blur', updateElapsedTime)
      window.addEventListener('beforeunload', sendTime)

      return () => {
        window.removeEventListener('focus', resetStartTime)
        window.removeEventListener('blur', updateElapsedTime)
        window.removeEventListener('beforeunload', sendTime)
        sendTime()
      }
    }
  }, [contentJson, eventUser])

  // useEffect(() => {
  //   if (contentJson && eventUser) {
  //     let maxScroll = 0

  //     const scrollDepth = new ScrollDepth({
  //       scrollElement: document.getElementById('viewport'),
  //       onEvent: ({ label, payload }) => {
  //         if (label === 'scroll') {
  //           maxScroll = Math.max(maxScroll, payload)

  //           if (payload >= 99) {
  //             scrollDepth.stop()
  //           }
  //         }
  //       },
  //     })

  //     scrollDepth.start()

  //     return () => {
  //       scrollDepth.stop()

  //       if (maxScroll > 0) {
  //         sendEvent({
  //           label: 'study-scroll',
  //           variables: eventUser,
  //           payload: { scroll: maxScroll },
  //         })
  //       }
  //     }
  //   }
  // }, [contentJson, eventUser])

  const menu = [
    ...(section?.hasSupplement && !isSupplement
      ? [
          <Button
            key="supplementButton"
            onClick={goToSupplement}
            disabled={isLoading}
          >
            Supplement
          </Button>,
        ]
      : []),
    <Button
      key="showSectionsButton"
      onClick={openSectionsModal}
      disabled={isLoading}
    >
      Sections
    </Button>,
    ...(isSupplement
      ? [
          <Button
            key="backToSectionButton"
            onClick={goToSection}
            disabled={isLoading}
          >
            Back to Section
          </Button>,
        ]
      : [
          <Button
            key="backToCourseButton"
            onClick={goToCourse}
            disabled={isLoading}
          >
            Back to Course
          </Button>,
        ]),
  ]

  return (
    <Layout page={{ title: course?.title || 'Course', menu }}>
      {isLoadingContent ? (
        <Loader big />
      ) : contentJson ? (
        <>
          <ScrollProgress />
          <Subheading className="page-sub">
            {isSupplement ? `${section.title} - Supplement` : section.title}
          </Subheading>
          <LexicalEditor value={contentJsonString} isEditable={false} />
          <DoneButton onClick={done}>Done</DoneButton>
        </>
      ) : (
        <>
          <Subheading className="page-sub">{section.title}</Subheading>
          <Subheading className="page-sub center">{noContent}</Subheading>
        </>
      )}
    </Layout>
  )
}

const DoneButton = styled.button`
  display: block;
  margin: ${GAP.md} auto;
  padding: 20px;
  width: 200px;
  text-align: center;
  font-size: ${TYPOGRAPHY.size.md};
  border: 0;
  border-radius: ${BORDER_RADIUS};
  background-color: ${COLOR.green};
  color: ${COLOR.black};
  appearance: none;
  cursor: pointer;

  &:hover {
    background-color: ${COLOR.blue};
    color: ${COLOR.white};
  }
`

export default Study
