import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { COLOR, Flex, GAP, Icon, MEDIA_QUERY, TYPOGRAPHY } from 'ui'
import { MAIN_ROUTE } from 'config'

const PageHeader = ({ title, menu }) => {
  const navigate = useNavigate()

  const goToMainRoute = () => {
    navigate(MAIN_ROUTE)
  }

  return (
    <Wrapper>
      <Flex alignItems="center" gap={GAP.xs}>
        {window.location.pathname !== MAIN_ROUTE && (
          <>
            <HomeButton onClick={goToMainRoute} title="Go to dashboard">
              {Icon.Home}
            </HomeButton>
            <span>/</span>
          </>
        )}
        <Title>{title}</Title>
      </Flex>
      {!!menu && <Menu>{menu.map((item) => item)}</Menu>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${GAP.sm};
  margin: 0;
  padding: ${GAP.xs} ${GAP.sm};
  width: 100%;
  background-color: ${COLOR.blue};
  color: ${COLOR.white};

  ${MEDIA_QUERY.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const HomeButton = styled.button`
  margin: 0;
  padding: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  appearance: none;
  border: 0;
  background-color: ${COLOR.black};
  border-radius: 8px;
  cursor: pointer;

  svg {
    transform: scale(0.8);
  }
`

const Title = styled.div`
  margin: 0;
  padding: 0;
  font-size: ${TYPOGRAPHY.size.md};

  ${MEDIA_QUERY.lg} {
    font-size: ${TYPOGRAPHY.size.lg};
  }
`

const Menu = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: ${GAP.xs};
  margin: 0;
  padding: 0;

  ${MEDIA_QUERY.md} {
    justify-content: flex-end;
  }

  button {
    background-color: transparent;
    color: ${COLOR.white};
    border-color: ${COLOR.white};
    font-size: 11px;
    padding: 8px 12px;

    &:hover {
      background-color: ${COLOR.white};
      color: ${COLOR.blue};
    }

    &:disabled {
      background-color: transparent;
      color: ${COLOR.black};
    }
  }
`

export default PageHeader
