import axios from 'axios'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import Layout from 'components/Layout'
import { LexicalEditor } from 'components/LexicalEditor'
import QuestionsModal from 'components/QuestionsModal'
import SectionsModal from 'components/SectionsModal'
import {
  BORDER_RADIUS,
  Button,
  COLOR,
  Loader,
  MEDIA_QUERY,
  Subheading,
} from 'ui'
import { apiCall, reportError } from 'utils'
import { useAppContext } from 'context'

const Edit = () => {
  const {
    user,
    setError,
    openModal,
    isLoading,
    setIsLoading,
    getCourseData,
    apiCache,
    setApiCache,
  } = useAppContext()
  const navigate = useNavigate()
  const { courseId, sectionNumber = '1' } = useParams()
  const location = useLocation()
  const isSupplement = location.pathname.endsWith('/supplement')
  const [isInit, setIsInit] = useState(true)
  const [isDirty, setIsDirty] = useState(true)
  // const [isEditable, setIsEditable] = useState(true)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [isLoadingContent, setIsLoadingContent] = useState(true)
  const course = apiCache.course[courseId]
  const sections = course?.sections || []
  const section = sections.find((c) => c.number === Number(sectionNumber))
  const sectionId = section?.id
  const sectionCacheKey = isSupplement ? `${sectionId}-supplement` : sectionId
  const contentJson = apiCache.content[courseId]?.[sectionCacheKey]
  const contentJsonString = [undefined, null].includes(contentJson)
    ? contentJson
    : JSON.stringify(contentJson)
  const [contentState, setContentState] = useState(contentJson)
  const isEditable = true

  const handleEditorChange = (editorState) => {
    setContentState(editorState.toJSON())
    setIsDirty(true)

    if (isInit) {
      document.getElementById('viewport').scrollTo(0, 0)
      setIsInit(false)
    }
  }

  const goToCourse = () => {
    navigate(`/course/${courseId}/`)
  }

  const goToEdit = (path) => () => {
    navigate(`/edit/${courseId}/${sectionNumber}${path}`)
  }

  const openQuestionsModal = () => {
    openModal({
      id: 'questions',
      content: <QuestionsModal courseId={courseId} sectionId={sectionId} />,
    })
  }

  const openSectionsModal = () => {
    openModal({
      id: 'sections',
      content: (
        <SectionsModal
          courseId={courseId}
          sectionNumber={Number(sectionNumber)}
          isEditMode={true}
        />
      ),
    })
  }

  // AI features
  // const checkLinks = async () => {
  //   setIsLoading(true)

  //   const query = `query (
  //     $query: String!,
  //     $variables: String,
  //     $input: String
  //     ) {
  //     ai(
  //       query: $query,
  //       variables: $variables,
  //       input: $input
  //     )
  //   }`
  //   const variables = {
  //     query: 'checkLinks',
  //     input: contentText,
  //   }

  //   await gql({ query, variables })
  //     .then(({ data: { ai } }) => {
  //       if (!!ai) {
  //         const links = JSON.parse(ai)
  //         alert(
  //           `These links are broken or redirecting:\n\n${links
  //             .map((e) => `- ${e.link}: ${e.desc}`)
  //             .join('\n')}`,
  //         )
  //       }
  //     })
  //     .catch((error) => {
  //       console.log('-- error =', error)
  //     })

  //   setIsLoading(false)
  // }

  // const checkDates = async () => {
  //   setIsLoading(true)

  //   const query = `query (
  //     $query: String!,
  //     $variables: String,
  //     $input: String
  //     ) {
  //     ai(
  //       query: $query,
  //       variables: $variables,
  //       input: $input
  //     )
  //   }`
  //   const variables = {
  //     query: 'checkDates',
  //     input: contentText,
  //   }

  //   await gql({ query, variables })
  //     .then(({ data: { ai } }) => {
  //       if (!!ai) {
  //         const dates = JSON.parse(ai)
  //         alert(
  //           `These due dates are in the past:\n\n${dates
  //             .map(
  //               (e) => `- ${new Date(e.date).toLocaleDateString()}: ${e.desc}`,
  //             )
  //             .join('\n')}`,
  //         )
  //       }
  //     })
  //     .catch((error) => {
  //       console.log('-- error =', error)
  //     })

  //   setIsLoading(false)
  // }

  // const generateQuestions = async () => {
  //   setIsLoading(true)

  //   const query = `query (
  //     $query: String!,
  //     $variables: String,
  //     $input: String
  //     ) {
  //     ai(
  //       query: $query,
  //       variables: $variables,
  //       input: $input
  //     )
  //   }`
  //   const variables = {
  //     query: 'newQuestions',
  //     variables: JSON.stringify({ courseId, sectionId }),
  //     input: contentText,
  //   }

  //   await gql({ query, variables })
  //     .then(({ data: { ai } }) => {
  //       if (!!ai) {
  //         const newQuestions = JSON.parse(ai)
  //         setSectionQuestions((prev) => [...prev, ...newQuestions])
  //         setModal(null) // TODO: fix modal update
  //         alert(
  //           '5 questions have been generated based on this section but they have not been saved yet. Review them and edit if needed.',
  //         )
  //       }
  //     })
  //     .catch((error) => {
  //       console.log('-- error =', error)
  //     })

  //   setIsLoading(false)
  // }

  const updateContentCache = (contentToCache) => {
    setApiCache({
      ...apiCache,
      content: {
        ...apiCache.content,
        [courseId]: {
          ...(apiCache.content[courseId] || {}),
          [sectionCacheKey]: contentToCache,
        },
      },
    })
  }

  const getSectionContent = async () => {
    setIsLoadingContent(true)

    if (contentJson !== undefined) {
      setTimeout(() => {
        setIsLoadingContent(false)
      }, 10)
      return
    }

    setIsLoading(true)

    const savedContent = await apiCall({
      query: 'getSectionContent',
      variables: {
        courseId,
        sectionId,
      },
      data: {
        isPublish: false,
        isSupplement,
      },
    }).catch((error) => {
      reportError({ source: 'getSectionContent', error })
      setError('Failed to fetch content')
      return null
    })

    updateContentCache(savedContent)

    // editorRef.current.editor.undoManager.clear();

    setIsLoading(false)
    setIsLoadingContent(false)
  }

  const saveSectionContent = async () => {
    setIsLoading(true)

    const saveUrl = await apiCall({
      query: 'getSaveContentUrl',
      variables: {
        courseId,
        sectionId,
      },
      data: {
        isSupplement,
      },
    }).catch((error) => {
      reportError({ source: 'saveSectionContent', error })
      setError('Failed to save content')
      return null
    })

    if (saveUrl) {
      await axios
        .put(saveUrl, contentState, {
          headers: {
            'Content-Type': 'application/json',
          },
          onUploadProgress: (progress) => {
            const percentUploaded = Math.round(
              (progress.loaded * 100) / progress.total,
            )
            setUploadPercent(percentUploaded)
          },
        })
        .catch((error) => {
          reportError({ source: 'saveSectionContent', error })
          setError('Failed to save content')
        })

      updateContentCache(contentState)
    }

    setIsLoading(false)
    setIsDirty(false)
  }

  const publishSectionContent = async () => {
    const confirmed = confirm('Publish section?') // eslint-disable-line no-restricted-globals
    if (!confirmed) return

    setIsLoading(true)

    const result = await apiCall({
      query: 'publishSectionContent',
      variables: {
        courseId,
        sectionId,
      },
      data: {
        isSupplement,
      },
    }).catch((error) => {
      reportError({ source: 'publishSectionContent', error })
      setError('Failed to publish content')
      return { success: false }
    })

    if (!result.success && result.code === 403) {
      setError(
        'This content is flagged as unsafe and was not published. Please double check the material and make sure it follows our terms of service and your local laws.',
      )
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (user && !course && courseId) {
      getCourseData({ courseId, isEditMode: true })
    }
  }, [user, course, courseId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sectionId) {
      setIsInit(true)
      setIsDirty(true)
      getSectionContent()
    }
  }, [sectionId, isSupplement]) // eslint-disable-line react-hooks/exhaustive-deps

  const menu = course
    ? [
        ...(isLoading
          ? [<span key="saveStatus">{`Processing... ${uploadPercent}%`}</span>]
          : []),
        ...(isEditable
          ? [
              ...(isDirty
                ? [
                    <Button
                      key="saveSectionContentButton"
                      onClick={saveSectionContent}
                      disabled={isLoading}
                    >
                      Save
                    </Button>,
                  ]
                : [
                    <Button
                      key="publishSectionContentButton"
                      onClick={publishSectionContent}
                      disabled={isLoading}
                    >
                      Publish
                    </Button>,
                  ]),
              ...(isSupplement
                ? [
                    <Button
                      key="backToSectionButton"
                      onClick={goToEdit('/')}
                      disabled={isLoading}
                    >
                      Back to Section
                    </Button>,
                  ]
                : [
                    ...(section?.hasSupplement
                      ? [
                          <Button
                            key="editSupplementButton"
                            onClick={goToEdit('/supplement')}
                            disabled={isLoading}
                          >
                            Supplement
                          </Button>,
                        ]
                      : []),
                    <Button
                      key="showQuestionsButton"
                      onClick={openQuestionsModal}
                      disabled={isLoading}
                    >
                      Questions
                    </Button>,
                    <Button
                      key="showSectionsButton"
                      onClick={openSectionsModal}
                      disabled={isLoading}
                    >
                      Sections
                    </Button>,
                    <Button
                      key="backToCourseButton"
                      onClick={goToCourse}
                      disabled={isLoading}
                    >
                      Back to Course
                    </Button>,
                  ]),
              // <Button
              //   key="checkLinksButton"
              //   onClick={checkLinks}
              //   disabled={isLoading}
              // >
              //   Check Links
              // </Button>,
              // <Button
              //   key="checkDatesButton"
              //   onClick={checkDates}
              //   disabled={isLoading}
              // >
              //   Check Dates
              // </Button>,
            ]
          : []),
        // <Button
        //   key="toggleEditModeButton"
        //   onClick={() => setIsEditable((prev) => !prev)}
        //   disabled={isLoading}
        // >
        //   {isEditable ? 'Preview' : 'Exit Preview'}
        // </Button>,
      ]
    : []

  return (
    <Layout
      page={{
        title: `${isEditable ? 'Edit' : 'Preview'}${
          course ? ` "${course.title}"` : ''
        }`,
        menu,
      }}
    >
      {isLoadingContent ? (
        <Loader />
      ) : (
        <>
          <EditorStyle />
          <Subheading className="page-sub">
            {isSupplement ? `${section.title} - Supplement` : section.title}
          </Subheading>
          <LexicalEditor
            value={contentJsonString}
            onChange={handleEditorChange}
            isEditable={isEditable}
          />
        </>
      )}
    </Layout>
  )
}

const EditorStyle = createGlobalStyle`
  .editor-shell {
    margin: 0 auto;

    ${MEDIA_QUERY.md} {
      margin: 20px auto;
    }
  }

  .editor-container {
    border-bottom-left-radius: ${BORDER_RADIUS} !important;
    border-bottom-right-radius: ${BORDER_RADIUS} !important;
  }

  .ContentEditable__root {
    min-height: calc(100vh - 300px);
    border-bottom-left-radius: ${BORDER_RADIUS};
    border-bottom-right-radius: ${BORDER_RADIUS};
    border: 1px solid transparent;
    transition: 0.2s border-color;

    /* &:focus {
      border-color: ${COLOR.green};
    } */
  }

  .floating-text-format-popup button.popup-item {
    padding: 2px 6px 3px 6px !important;
  }
`

export default Edit
