import { forwardRef } from 'react'
import styled from 'styled-components'
import { COLOR, GAP, TYPOGRAPHY } from './theme'

const Input = forwardRef(({ isMultiLine, label, error, ...props }, ref) => (
  <Wrapper>
    {!!label && <Label>{label}</Label>}
    <InputComponent
      ref={ref}
      $invalid={!!error}
      {...props}
      {...(isMultiLine ? { as: 'textarea', rows: 3 } : {})}
    />
    {!!error && <Error>{error.message}</Error>}
  </Wrapper>
))

const Wrapper = styled.label`
  display: block;
  margin: 0;
  padding: 0;

  form & {
    margin-bottom: ${GAP.md};
  }
`

const InputComponent = styled.input`
  margin: 0;
  padding: ${GAP.xs};
  width: 100%;
  background-color: ${COLOR.white};
  color: ${COLOR.black};
  border: 1px solid ${COLOR.black};
  border-radius: 8px;
  font-size: ${TYPOGRAPHY.size.sm};
  transition: 0.3s all;
  outline: none;

  &:focus&:not(:read-only) {
    border-color: ${COLOR.green};
  }

  &:read-only {
    border-color: ${COLOR.gray};
    background-color: ${COLOR.lightGray};
  }

  ${({ $invalid }) =>
    $invalid
      ? `
          border-color: ${COLOR.alert} !important;

          &:focus {
            border-color: ${COLOR.alert} !important;
          }
        `
      : ``}
`

const Label = styled.span`
  display: block;
  margin: 0 0 ${GAP.xxs} 0;
  color: ${COLOR.black};
  font-size: ${TYPOGRAPHY.size.sm};
`

const Error = styled.div`
  margin: ${GAP.xxs} 0 0 0;
  font-size: ${TYPOGRAPHY.size.xs};
  color: ${COLOR.alert};
`

export default Input
