import { Chart, registerables } from 'chart.js'
import { useEffect } from 'react'
import styled from 'styled-components'
import Layout from 'components/Layout'
import { Button, COLOR, GAP, Loader } from 'ui'
import {
  apiCall,
  capitalize,
  getRandomColour,
  htmlToImage,
  secondsToMinutes,
} from 'utils'
import { useAppContext } from 'context'

Chart.register(...registerables)

const StudyPattern = () => {
  const { isLoading, setIsLoading, apiCache, setApiCache, setError } =
    useAppContext()
  const studyPattern = apiCache.studyPattern

  const getStudyPattern = async () => {
    setIsLoading(true)

    const fetchedData = await apiCall({
      query: 'getStudyPattern',
    }).catch((error) => {
      reportError({ source: 'getStudyPattern', error })
      setError('Failed to get study pattern')
      return null
    })

    if (fetchedData) {
      setApiCache({
        ...apiCache,
        studyPattern: fetchedData,
      })
    }

    setIsLoading(false)
  }

  const renderCharts = () => {
    const studyDevice = studyPattern.stats.study.device
    const studyTimeOfDay = studyPattern.stats.study.timeOfDay
    const quizScoreByTimeOfDay = studyPattern.stats.quiz.timeOfDay.avgScore

    const studyTimesOfDay = Object.keys(studyTimeOfDay).reduce(
      (obj, e) => {
        obj.labels.push(capitalize(e))
        obj.data.push(secondsToMinutes(studyTimeOfDay[e]))
        obj.backgroundColors.push(getRandomColour())
        return obj
      },
      { labels: [], data: [], backgroundColors: [] },
    )
    const devices = Object.keys(studyDevice).reduce(
      (obj, e) => {
        obj.labels.push(capitalize(e))
        obj.data.push(studyDevice[e])
        obj.backgroundColors.push(getRandomColour())
        return obj
      },
      { labels: [], data: [], backgroundColors: [] },
    )
    const quizScoresByTimeOfDay = Object.keys(quizScoreByTimeOfDay).reduce(
      (obj, e) => {
        obj.labels.push(capitalize(e))
        obj.data.push(quizScoreByTimeOfDay[e])
        return obj
      },
      { labels: [], data: [] },
    )

    new Chart(document.getElementById('timeofday-chart'), {
      options: {
        responsive: true,
        scales: {
          x: {
            title: { display: true, color: COLOR.black, text: 'Time of Day' },
          },
          y: {
            position: 'left',
            ticks: { stepSize: 5 },
            title: { display: true, color: COLOR.black, text: 'Minutes' },
          },
          score: {
            type: 'linear',
            position: 'right',
            ticks: { stepSize: 20 },
            min: 0,
            max: 100,
            bounds: 'ticks',
            grid: { display: false },
            title: {
              display: true,
              color: COLOR.black,
              text: 'Quiz Score (%)',
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      data: {
        labels: studyTimesOfDay.labels,
        datasets: [
          {
            type: 'bar',
            order: 2,
            label: 'Study Minutes',
            data: studyTimesOfDay.data,
            backgroundColor: studyTimesOfDay.backgroundColors,
          },
          {
            yAxisID: 'score',
            type: 'line',
            order: 1,
            label: 'Average Quiz Score',
            data: quizScoresByTimeOfDay.data,
            backgroundColor: COLOR.black,
            borderColor: COLOR.black,
            pointRadius: 3,
            lineTension: 0.2,
            spanGaps: true,
          },
        ],
      },
    })

    new Chart(document.getElementById('device-chart'), {
      options: {
        responsive: true,
        scales: {
          x: {
            title: { display: true, color: COLOR.black, text: 'Device' },
          },
          y: {
            position: 'left',
            ticks: { stepSize: 1 },
            title: { display: true, color: COLOR.black, text: 'Count' },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      data: {
        labels: devices.labels,
        datasets: [
          {
            type: 'bar',
            data: devices.data,
            backgroundColor: devices.backgroundColors,
          },
        ],
      },
    })
  }

  useEffect(() => {
    if (studyPattern) {
      renderCharts()
    } else {
      getStudyPattern()
    }
  }, [studyPattern]) // eslint-disable-line react-hooks/exhaustive-deps

  const menu = [
    ...(!!studyPattern
      ? [
          <Button
            key="exportButton"
            onClick={htmlToImage({
              elementId: 'charts',
              filename: 'Study Pattern',
            })}
          >
            Export
          </Button>,
        ]
      : []),
  ]

  return (
    <Layout page={{ title: 'Study Pattern', menu }}>
      {isLoading ? (
        <Loader big />
      ) : (
        <Wrapper id="charts">
          <canvas id="timeofday-chart" />
          <canvas id="device-chart" />
        </Wrapper>
      )}
    </Layout>
  )
}

const Wrapper = styled.div`
  canvas {
    margin: ${GAP.lg} 0;
    padding: 0;
  }
`

export default StudyPattern
