import { Redirect } from 'App'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Column from 'components/Column'
import CourseModal from 'components/CourseModal'
// import InviteLearnerModal from 'components/InviteLearnerModal'
// import EnrollModal from 'components/EnrollModal'
import Layout from 'components/Layout'
import {
  BORDER_RADIUS,
  Button,
  COLOR,
  Flex,
  GAP,
  Grid,
  Loader,
  Paragraph,
  Subheading,
  TYPOGRAPHY,
} from 'ui'
import { apiCall, reportError } from 'utils'
import { useAppContext } from 'context'
import { SIGNOUT_URL } from 'config'

const Dashboard = () => {
  const {
    setIsLoading,
    setError,
    openModal,
    closeModal,
    user,
    getUserData,
    courses,
    enrolls,
    notifications,
    // getNotifications,
  } = useAppContext()
  const navigate = useNavigate()
  const systemItems = [
    {
      id: 'system-account',
      title: 'Account',
      description: 'Details, subscription, etc.',
      to: '/account',
    },
    ...(Object.keys(user?.enrollIds || {}).length
      ? [
          {
            id: 'system-profile',
            title: 'Study Pattern',
            description: 'Visualize your habits',
            to: '/pattern',
          },
          {
            id: 'system-progress',
            title: 'Topics',
            description: 'Visualize your learnings',
            to: '/topics',
          },
        ]
      : []),
  ]

  const goToCourse = (courseId) => () => {
    navigate(`/course/${courseId}/`)
  }

  const navigateTo = (path) => () => {
    navigate(path)
  }

  const createCourse = async ({ title, description }) => {
    setIsLoading(true)

    const success = await apiCall({
      query: 'putCourse',
      data: {
        title,
        description,
      },
    }).catch((error) => {
      reportError({ source: 'createCourse', error })
      setError('Failed to create course')
      return false
    })

    if (success) {
      closeModal('createCourse')
      getUserData()
    }

    setIsLoading(false)
  }

  const deleteCourse = (courseId) => async (e) => {
    e.stopPropagation()

    const confirmed = confirm('Delete course and all its content?') // eslint-disable-line no-restricted-globals
    if (!confirmed) return

    setIsLoading(true)

    const success = await apiCall({
      query: 'deleteCourse',
      variables: { courseId },
    }).catch((error) => {
      reportError({ source: 'deleteCourse', error })
      setError('Failed to delete course')
      return false
    })

    if (success) {
      getUserData()
    }

    setIsLoading(false)
  }

  // const enrollInClass = async ({ courseId, classId }) => {
  //   setIsLoading(true)

  //   const success = await apiCall({
  //     query: 'enrollInClass',
  //     variables: { courseId, classId },
  //   }).catch((error) => {
  //     reportError({ source: 'enrollInClass', error })
  //     setError('Failed to enroll in course')
  //     return false
  //   })

  //   if (success) {
  //     closeModal('enroll')
  //     getUserData()
  //   }

  //   setIsLoading(false)
  // }

  // const inviteLearner = async ({
  //   course: { value: courseId, label: courseTitle },
  //   learnerEmail,
  // }) => {
  //   setIsLoading(true)

  //   const success = await apiCall({
  //     query: 'sendInvite',
  //     variables: {
  //       email: learnerEmail,
  //       targetId: courseId,
  //       type: 'course',
  //       name: courseTitle,
  //     },
  //   }).catch((error) => {
  //     reportError({ source: 'inviteLearner', error })
  //     setError('Failed to invite to course')
  //     return false
  //   })

  //   if (success) {
  //     closeModal('invite')
  //   }

  //   setIsLoading(false)
  // }

  const clearNotifications = async () => {
    //   // eslint-disable-next-line
    //   const confirmed = confirm('Delete all notifications?');
    //   if (!confirmed) return;
    //   const query = `mutation {
    //     clearNotifications
    //   }`;
    //   await gql({ query })
    //     .then(({ data: { clearNotifications: success } }) => {
    //       if (success) {
    //         getNotifications();
    //       }
    //     })
    //     .catch((error) => {
    //       console.log('-- error =', error);
    //     });
  }

  const openCreateCourseModal = () => {
    openModal({
      id: 'createCourse',
      onSubmit: createCourse,
      content: <CourseModal />,
    })
  }

  // const openEnrollInCourseModal = () => {
  //   openModal({
  //     id: 'enroll',
  //     onSubmit: enrollInClass,
  //     content: <EnrollModal />,
  //   })
  // }

  // const openInviteLearnerModal = () => {
  //   openModal({
  //     id: 'invite',
  //     content: <InviteLearnerModal onSubmit={inviteLearner} />,
  //   })
  // }

  const menu = [
    ...(!!user
      ? [
          <Button
            key="logoutButton"
            onClick={() => Redirect({ to: SIGNOUT_URL })}
          >
            Sign Out
          </Button>,
        ]
      : []),
  ]

  return (
    <Layout page={{ title: 'Dashboard', menu }} maxWidth={false}>
      <Grid cols="25% 25% 25% 25%">
        <Column title="Created Courses" button={openCreateCourseModal}>
          {!courses && <ColumnLoader />}
          {!!courses && !courses.length && (
            <ColumnNote>You haven't created any courses</ColumnNote>
          )}
          {courses?.map((c) => (
            <ColumnItem key={c.id} onClick={goToCourse(c.id)}>
              <Flex dir="column">
                <Subheading>{c.title}</Subheading>
                {c.description && <em>{c.description}</em>}
              </Flex>
              <button onClick={deleteCourse(c.id)}>&times;</button>
            </ColumnItem>
          ))}
        </Column>

        {/* <Column title="Enrolled Courses" button={openEnrollInCourseModal}> */}
        <Column title="Enrolled Courses">
          {!enrolls && <ColumnLoader />}
          {!!enrolls && !enrolls.length && (
            <ColumnNote>Not enrolled in any courses</ColumnNote>
          )}
          {enrolls?.map((c) => (
            <ColumnItem key={c.id} onClick={goToCourse(c.id)}>
              <Flex dir="column">
                <Subheading>{c.title}</Subheading>
                {c.description && <em>{c.description}</em>}
              </Flex>
            </ColumnItem>
          ))}
        </Column>

        {/* <Column
          title='People'
          {...(isTeacher ? { button: openInviteLearnerModal } : {})}
        >
          {!people && <ColumnLoader />}
          {!!people && !people.length && <ColumnNote>No people</ColumnNote>}
          {!!people &&
            people.map((item) => (
              <ColumnItem key={item.id}>
                <Flex dir='column'>
                  <Subheading>
                    {item.firstName} {item.lastName}
                  </Subheading>
                  {(user.city || user.country) && (
                    <em>
                      From {user.city} {user.country}
                    </em>
                  )}
                </Flex>
              </ColumnItem>
            ))}
        </Column> */}

        <Column
          title="Notifications"
          {...(notifications.length ? { button: clearNotifications } : {})}
          x
        >
          {!notifications && <ColumnLoader />}
          {!!notifications && !notifications.length && (
            <ColumnNote>No notifications</ColumnNote>
          )}
          {notifications?.map((item) => (
            <ColumnItem key={item.id}>
              <Flex dir="column">
                <Subheading>{item.title}</Subheading>
                {item.message && <em>{item.message}</em>}
              </Flex>
            </ColumnItem>
          ))}
        </Column>

        <Column title="Profile">
          {!user && <ColumnLoader />}
          {!!user &&
            systemItems.map((item) => (
              <ColumnItem key={item.id} onClick={navigateTo(item.to)}>
                <Flex dir="column">
                  <Subheading>{item.title}</Subheading>
                  {item.description && <em>{item.description}</em>}
                </Flex>
              </ColumnItem>
            ))}
        </Column>
      </Grid>
    </Layout>
  )
}

const ColumnLoader = () => (
  <Flex justifyContent="center" margin={GAP.sm}>
    <Loader small inline />
  </Flex>
)

const ColumnNote = styled(Paragraph)`
  margin: 0;
  padding: ${GAP.md};
  text-align: center;
  font-style: italic;
`

const ColumnItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: ${GAP.sm};
  color: ${COLOR.black};
  font-size: ${TYPOGRAPHY.size.sm};
  text-decoration: none;
  hyphens: auto;
  -webkit-hyphens: auto;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 0;
  }

  em {
    display: block;
    margin-top: ${GAP.xxs};
    color: #999;
    font-size: ${TYPOGRAPHY.size.sm};
  }

  &:hover {
    background-color: ${COLOR.black} !important;
    border-radius: ${BORDER_RADIUS};

    h2 {
      color: ${COLOR.green};
    }

    button {
      opacity: 1;
      pointer-events: all;
    }
  }

  button {
    margin: 0;
    padding: 0;
    border: 0;
    min-width: 20px;
    height: 20px;
    line-height: 18px;
    border-radius: 3px;
    background-color: ${COLOR.lightGray};
    color: ${COLOR.black};
    font-size: 18px;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
  }
`

export default Dashboard
