import { useEffect, useState } from 'react'
import styled from 'styled-components'
import QuestionModal from 'components/QuestionModal'
import {
  BORDER_RADIUS,
  Box,
  Button,
  COLOR,
  Flex,
  GAP,
  Heading,
  Loader,
  Paragraph,
  Subheading,
  TYPOGRAPHY,
} from 'ui'
import { apiCall } from 'utils'
import { useAppContext } from 'context'

const QuestionList = ({ questions, editQuestion, deleteQuestion, type }) => {
  if (!questions || questions.length === 0) {
    return <Paragraph>No questions added to {type} yet.</Paragraph>
  }
  return (
    <>
      <Small>
        Total {type} questions: {questions.length}
      </Small>
      {questions.map((q) => (
        <QuestionBox key={q.id}>
          <Subheading>{q.question}</Subheading>
          <Small>Weight: {q.weight}</Small>
          <Answer $isCorrect={q.correct === 'option1'}>1) {q.option1}</Answer>
          <Answer $isCorrect={q.correct === 'option2'}>2) {q.option2}</Answer>
          {q.option3 && (
            <Answer $isCorrect={q.correct === 'option3'}>3) {q.option3}</Answer>
          )}
          {q.option4 && (
            <Answer $isCorrect={q.correct === 'option4'}>4) {q.option4}</Answer>
          )}
          <Paragraph>{q.explanation}</Paragraph>
          <Flex justifyContent="flex-end" gap={GAP.xs}>
            <Button
              onClick={() => {
                const confirmed = confirm('Delete question?') // eslint-disable-line no-restricted-globals
                if (!confirmed) return
                deleteQuestion({
                  questionId: q.id,
                  isCourseLevel: type === 'course',
                })
              }}
            >
              Delete
            </Button>
            <Button primary onClick={editQuestion(q)}>
              Edit
            </Button>
          </Flex>
        </QuestionBox>
      ))}
    </>
  )
}

const QuestionsModal = ({ courseId, sectionId, closeModal }) => {
  const { setError, openModal, apiCache, setApiCache } = useAppContext()
  const [isLoading, setIsLoading] = useState(false)
  // const [showGenerateButton, setShowGenerateButton] = useState(true)
  const sectionQuestions = apiCache.question[courseId]?.[sectionId]
  // const courseQuestions = apiCache.question[courseId]?.course

  // const handleTabChange = (tab) => {
  //   // setShowGenerateButton(tab === 'Section')
  //   if (tab === 'Course' && !courseQuestions) {
  //     getQuestions(true)
  //   }
  // }

  const openAddQuestionModal = (targetQuestion) => () => {
    openModal({
      id: 'addQuestion',
      onSubmit: saveQuestion,
      content: <QuestionModal question={targetQuestion} />,
    })
  }

  const getQuestions = async (isCourseLevel) => {
    setIsLoading(true)

    const savedQuestions = await apiCall({
      query: 'getQuestions',
      variables: {
        courseId,
        ...(isCourseLevel ? {} : { sectionId }),
      },
      data: {
        isQuiz: false,
      },
    }).catch((error) => {
      reportError({ source: 'getQuestions', error })
      setError('Failed to fetch questions')
      return null
    })

    const targetCacheLevel = isCourseLevel ? 'course' : sectionId

    setApiCache({
      ...apiCache,
      question: {
        ...apiCache.question,
        [courseId]: {
          ...(apiCache.question[courseId] || {}),
          [targetCacheLevel]: savedQuestions,
        },
      },
    })

    setIsLoading(false)
  }

  const saveQuestion = async (questionData) => {
    setIsLoading(true)

    const { type, id: questionId, ...questionFields } = questionData
    // const isCourseLevel = type.value === 'course'
    const isCourseLevel = false

    const success = await apiCall({
      query: 'putQuestion',
      variables: {
        courseId,
        ...(isCourseLevel ? {} : { sectionId }),
        ...(questionId ? { questionId } : {}),
      },
      data: {
        ...questionFields,
        id: questionId,
        courseId,
        ...(isCourseLevel ? {} : { sectionId }),
        weight: parseInt(questionFields.weight),
        correct: questionFields.correct.value,
      },
    }).catch((error) => {
      reportError({ source: 'saveQuestion', error })
      setError('Failed to save question')
      return false
    })

    if (success) {
      await getQuestions(isCourseLevel)
    }

    setIsLoading(false)
  }

  const deleteQuestion = async ({ questionId, isCourseLevel }) => {
    const confirmed = confirm('Delete question?') // eslint-disable-line no-restricted-globals
    if (!confirmed) return

    setIsLoading(true)

    const success = await apiCall({
      query: 'deleteQuestion',
      variables: {
        courseId,
        ...(isCourseLevel ? {} : { sectionId }),
        questionId,
      },
    }).catch((error) => {
      reportError({ source: 'deleteQuestion', error })
      setError('Failed to delete question')
      return false
    })

    if (success) {
      await getQuestions(isCourseLevel)
    }

    setIsLoading(false)
  }

  const generateQuestions = async () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(
      "This will generate 10 questions. If you've already used this button, the system will generate the same questions as before. Do you wish to continue?",
    )
    if (!confirmed) return

    setIsLoading(true)

    const success = await apiCall({
      query: 'generateQuestions',
      variables: {
        courseId,
        sectionId,
      },
    }).catch((error) => {
      reportError({ source: 'generateQuestions', error })
      setError('Failed to generate questions')
      return false
    })

    if (success) {
      await getQuestions()
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (courseId && sectionId && !sectionQuestions) {
      getQuestions()
    }
  }, [courseId, sectionId, sectionQuestions]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Flex justifyContent="space-between" margin={`0 0 ${GAP.md} 0`}>
        <Heading secondary margin="0">
          Questions
        </Heading>
        <Flex justifyContent="flex-end">
          {!isLoading && (
            <>
              <Button
                primary
                onClick={generateQuestions}
                margin={`0 ${GAP.xs} 0 0`}
              >
                Generate
              </Button>
              <Button
                primary
                onClick={openAddQuestionModal()}
                margin={`0 ${GAP.xs} 0 0`}
              >
                Add
              </Button>
            </>
          )}
          <Button onClick={closeModal}>Close</Button>
        </Flex>
      </Flex>
      {isLoading ? (
        <Loader />
      ) : (
        <QuestionList
          type="section"
          questions={sectionQuestions}
          editQuestion={openAddQuestionModal}
          deleteQuestion={deleteQuestion}
        />
      )}
      {/* <Tabs
        onChange={handleTabChange}
        data={[
          {
            title: 'Section',
            content: isLoading ? (
              <Loader />
            ) : (
              QuestionList({
                type: 'section',
                questions: sectionQuestions,
                editQuestion: openAddQuestionModal,
                deleteQuestion,
              })
            ),
          },
          {
            title: 'Course',
            content: isLoading ? (
              <Loader />
            ) : (
              QuestionList({
                type: 'course',
                questions: courseQuestions,
                editQuestion: openAddQuestionModal,
                deleteQuestion,
              })
            ),
          },
        ]}
      /> */}
    </Box>
  )
}

const QuestionBox = styled(Box)`
  background-color: ${COLOR.lightGray};
  margin-bottom: ${GAP.md};
  padding: ${GAP.sm};
  border-radius: ${BORDER_RADIUS};

  button {
    padding: ${GAP.xxs} ${GAP.xs};
    font-size: ${TYPOGRAPHY.size.xs};
  }
`

const Small = styled(Paragraph)`
  font-size: ${TYPOGRAPHY.size.xs};
  font-style: italic;
`

const Answer = styled(Paragraph)`
  ${({ $isCorrect }) => ($isCorrect ? 'font-weight: bold;' : '')}
`

export default QuestionsModal
