import styled from 'styled-components'
import ErrorBanner from 'components/ErrorBanner'
import Header from 'components/Header'
import Modal from 'components/Modal'
import { COLOR, MAX_WIDTH } from 'ui'
import { useAppContext } from 'context'

const Layout = ({ children, page, maxWidth = true }) => {
  const { modals } = useAppContext()

  return (
    <>
      <Viewport id="viewport">
        <Header page={page} />
        <Main $maxWidth={maxWidth}>{children}</Main>
      </Viewport>
      {modals.map((e) => (
        <Modal key={`modal-${e.id}`} modal={e} />
      ))}
      <ErrorBanner />
    </>
  )
}

export const viewportUnfocusClass = 'unfocus'

const Viewport = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${COLOR.white};
  transition: all 0.5s;

  &.${viewportUnfocusClass} {
    transform: scale(0.85);
    filter: blur(4px);
    pointer-events: none;
    user-select: none;
  }
`

const Main = styled.main`
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${MAX_WIDTH};` : '')}
`

export default Layout
