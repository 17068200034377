import { useState } from 'react'
import styled from 'styled-components'
import { COLOR, GAP } from './theme'

const Tabs = ({ data, onChange }) => {
  const [current, setCurrent] = useState(0)

  const tabs = []
  const content = []

  data.forEach((e) => {
    tabs.push(e.title)
    content.push(e.content)
  })

  const setTab = (index) => () => {
    setCurrent(index)
    onChange?.(tabs[index])
  }

  return (
    <Wrapper>
      <TabWrapper>
        {tabs.map((t, i) => (
          <Tab key={`tab-${t}`} onClick={setTab(i)} active={i === current}>
            {t}
          </Tab>
        ))}
      </TabWrapper>
      {content[current]}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0;
  background-color: ${COLOR.white};
  border-radius: 4px;
`

const TabWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0 0 ${GAP.sm} 0;
`

const Tab = styled.div`
  margin: 0;
  padding: ${GAP.sm};
  color: ${COLOR.black};
  border-radius: 4px;
  cursor: pointer;

  ${({ active }) =>
    active
      ? `
        background-color: ${COLOR.lightGray};
      `
      : ''}

  &:hover {
    background-color: ${COLOR.blue};
    color: ${COLOR.white};
  }
`

export default Tabs
