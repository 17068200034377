import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import SectionModal from 'components/SectionModal'
import {
  BORDER_RADIUS,
  Box,
  Button,
  COLOR,
  Flex,
  GAP,
  Heading,
  Loader,
  Paragraph,
  Subheading,
  TYPOGRAPHY,
} from 'ui'
import { apiCall } from 'utils'
import { useAppContext } from 'context'

const SectionsModal = ({
  courseId,
  sectionNumber,
  closeModal,
  isEditMode = false,
}) => {
  const navigate = useNavigate()
  const { setError, openModal, getCourseData, apiCache } = useAppContext()
  const [isLoading, setIsLoading] = useState(false)
  const course = apiCache.course[courseId]
  const sections = course?.sections || []

  const openSectionModal = (targetSectionId) => {
    openModal({
      id: 'addSection',
      onSubmit: saveSection,
      content: (
        <SectionModal
          section={
            targetSectionId
              ? sections.find((c) => c.id === targetSectionId)
              : undefined
          }
          deleteSection={
            sections.length > 1 && targetSectionId ? deleteSection : undefined
          }
        />
      ),
    })
  }

  const saveSection = async (sectionData) => {
    setIsLoading(true)

    const success = await apiCall({
      query: 'putSection',
      variables: {
        courseId,
        ...(sectionData.id ? { sectionId: sectionData.id } : {}),
      },
      data: sectionData,
    }).catch((error) => {
      reportError({ source: 'saveSection', error })
      setError('Failed to save section')
      return false
    })

    if (success) {
      await getCourseData({ courseId, isEditMode })
    }

    setIsLoading(false)
  }

  const deleteSection = (sectionId) => async (e) => {
    e?.preventDefault()

    const confirmed = confirm('Delete section?') // eslint-disable-line no-restricted-globals
    if (!confirmed) return

    setIsLoading(true)

    const success = await apiCall({
      query: 'deleteSection',
      variables: {
        courseId,
        sectionId,
      },
    }).catch((error) => {
      reportError({ source: 'deleteSection', error })
      setError('Failed to delete section')
      return false
    })

    const targetSectionNumber = sections.find((c) => c.id === sectionId).number

    if (success) {
      if (targetSectionNumber === 1 && sectionNumber === 1) {
        window.location.reload()
      } else if (targetSectionNumber === sectionNumber) {
        navigate(`/edit/${courseId}/${targetSectionNumber - 1}`)
      }
      await getCourseData({ courseId, isEditMode })
    }

    setIsLoading(false)
  }

  return (
    <Box>
      <Flex justifyContent="space-between" margin={`0 0 ${GAP.md} 0`}>
        <Heading secondary margin="0">
          Sections
        </Heading>
        <Flex justifyContent="flex-end">
          {isEditMode && !isLoading && (
            <Button
              primary
              onClick={() => {
                openSectionModal()
              }}
              margin={`0 ${GAP.xs} 0 0`}
            >
              Add
            </Button>
          )}
          <Button onClick={closeModal}>Close</Button>
        </Flex>
      </Flex>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!sections?.length && <Paragraph>No sections added yet.</Paragraph>}
          {sections?.length > 0 && (
            <Small>Total sections: {sections.length}</Small>
          )}
          {sections?.map((c) => (
            <SectionBox key={c.id}>
              <Subheading>
                {c.number}. {c.title}
              </Subheading>
              <Paragraph>{c.description}</Paragraph>
              <Flex justifyContent="flex-end" gap={GAP.xs}>
                {isEditMode && (
                  <Button
                    onClick={() => {
                      openSectionModal(c.id)
                    }}
                  >
                    Settings
                  </Button>
                )}
                <Button
                  primary
                  onClick={(e) => {
                    navigate(
                      `/${isEditMode ? 'edit' : 'study'}/${courseId}/${
                        c.number
                      }`,
                    )
                    closeModal(e)
                  }}
                >
                  {isEditMode ? 'Edit' : 'Study'}
                </Button>
              </Flex>
            </SectionBox>
          ))}
        </>
      )}
    </Box>
  )
}

const SectionBox = styled(Box)`
  background-color: ${COLOR.lightGray};
  margin-bottom: ${GAP.md};
  padding: ${GAP.sm};
  border-radius: ${BORDER_RADIUS};

  button {
    padding: ${GAP.xxs} ${GAP.xs};
    font-size: ${TYPOGRAPHY.size.xs};
  }
`

const Small = styled(Paragraph)`
  font-size: ${TYPOGRAPHY.size.xs};
  font-style: italic;
`

export default SectionsModal
