import App from 'App'
import { createRoot } from 'react-dom/client'

// import { IS_PROD } from 'config'

const domNode = document.getElementById('app')
const root = createRoot(domNode)
root.render(<App />)

// if (IS_PROD && 'serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('/sw.js')
//     .catch((error) => reportError({ source: 'sw', error }))
// }
